<template>
	<div class="page-container">
		<router-link tag="div" class="flex flex-wrap content-center rounded-lg bg-white h-12 px-4 mb-3 border-2" v-for="(property, index) in properties" :key="index" :to="`/settings/properties/${property.property_id}`">		
			<div class="container max-w-full flex justify-between">
				<span class="text-yellow-500 font-semibold">{{ property.property }}</span>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
				</svg>
			</div>
		</router-link>
		<router-link class="btn-fab" to="/settings/properties/add">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
		</router-link>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'

	export default{
		data: () => ({
			isLoading: true
		}),
		computed: {
			...mapState({
				properties: state => state.propertyModule.properties
			})
		},
		methods: {
			async getProperties(){
				await this.$store.dispatch('fetchProperties')
			}
		},
		async mounted(){
			this.isLoading = true
			await this.getProperties();
			this.isLoading = false
		}
	}
</script> 