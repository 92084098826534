<template>
    <div class="page-container">
        <template v-if="!showReturnsAnalysis">
            <div class="grid grid-cols-2 gap-1 w-full mb-5">
                <div class="text-yellow-500 text-center col-span-2 mb-3">Property Details</div>
                <div class="custom-text-field">
                    <input name="purchase_price" id="purchase_price" type="text" placeholder=" " autocomplete="nope" v-model="purchasePrice" />
                    <label for="purchase_price">Purchase Price($)</label>
                </div>
                <div class="custom-text-field">
                    <input name="costs" id="costs" type="text" placeholder=" " autocomplete="nope" v-model="costs" />
                    <label for="costs">Costs To Make Rent Ready</label>
                </div>
                <div class="custom-text-field">
                    <input name="down_payment" id="down_payment" type="text" placeholder=" " autocomplete="nope" v-model="downPaymentPercent" />
                    <label for="down_payment">Down Payment(%)</label>
                </div>
                <div class="custom-text-field">
                    <input name="closing_costs" id="closing_costs" type="text" placeholder=" " autocomplete="nope" v-model="closingCostsPercent" />
                    <label for="closing_costs">Closing Costs(%)</label>
                </div>
                <div class="border-2 w-full bg-white p-3 mb-2 rounded-md text-right mx-px">
                    <div class="text-gray-500 text-xs">$ {{ downPayment | thousandSeparator }}</div>
                </div>
                <div class="border-2 w-full bg-white p-3 mb-2 rounded-md text-right mx-px">
                    <div class="text-gray-500 text-xs">$ {{ closingCosts| thousandSeparator }}</div>
                </div>
                <div class="custom-text-field">
                    <input name="years" id="years" type="text" placeholder=" " autocomplete="nope" v-model="yearsToPayoff" />
                    <label for="years">Years To Payoff</label>
                </div>
                <div class="custom-text-field">
                    <input name="interest_rate" id="interest_rate" type="text" placeholder=" " autocomplete="nope" v-model="interestRate" />
                    <label for="interest_rate">Interest Rate(%)</label>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-1 w-full mb-5">
                <div class="text-yellow-500 text-center col-span-2 mb-3">Monthly Income</div>
                <div class="custom-text-field">
                    <input name="rents" id="rents" type="text" placeholder=" " autocomplete="nope" v-model="rent" />
                    <label for="rents">Rents</label>
                </div>
                <div class="custom-text-field">
                    <input name="utilities" id="utilities" type="text" placeholder=" " autocomplete="nope" v-model="utilities" />
                    <label for="utilities">Utilities</label>
                </div>
                <div class="custom-text-field">
                    <input name="vacancy" id="vacancy" type="text" placeholder=" " autocomplete="nope" v-model="vacancyPercent" />
                    <label for="vacancy">Vacancy(%)</label>
                </div>
                <div class="border-2 w-full bg-white p-3 mb-2 rounded-md text-right mx-px">
                    <div class="text-gray-500 text-xs">$ ({{ vacancy | thousandSeparator }})</div>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-1 w-full mb-5">
                <div class="text-yellow-500 text-center col-span-2 mb-3">Yearly Expenses</div>
                <div class="custom-text-field">
                    <input name="maintenance" id="maintenance" type="text" placeholder=" " autocomplete="nope" v-model="maintenancePercent" />
                    <label for="maintenance">Maintenance(%)</label>
                </div>
                <div class="custom-text-field">
                    <input name="management" id="management" type="text" placeholder=" " autocomplete="nope" v-model="managementPercent" />
                    <label for="management">Management(%)</label>
                </div>
                <div class="border-2 w-full bg-white p-3 mb-2 rounded-md text-right mx-px">
                    <div class="text-gray-500 text-xs">$ ({{ maintenance | thousandSeparator }})</div>
                </div>
                <div class="border-2 w-full bg-white p-3 mb-2 rounded-md text-right mx-px">
                    <div class="text-gray-500 text-xs">$ ({{ management | thousandSeparator }})</div>
                </div>
                <div class="custom-text-field">
                    <input name="yearly_utilities" id="yearly_utilities" type="text" placeholder=" " autocomplete="nope" v-model="yearlyUtilities" />
                    <label for="yearly_utilities">Yearly Utilities</label>
                </div>
                <div class="custom-text-field">
                    <input name="additional_expenses" id="additional_expenses" type="text" placeholder=" " autocomplete="nope" v-model="additionalExpenses" />
                    <label for="additional_expenses">Additional Expenses</label>
                </div>
            <div class="custom-text-field">
                    <input name="insurance" id="insurance" type="text" placeholder=" " autocomplete="nope" v-model="insurance" />
                    <label for="insurance">Insurance</label>
                </div>
                <div class="custom-text-field">
                    <input name="taxes" id="taxes" type="text" placeholder=" " autocomplete="nope" v-model="taxes" />
                    <label for="taxes">Taxes</label>
                </div>
            </div>
            <button class="btn btn-primary mb-3" @click="computeAmortization">Run Numbers</button>
        </template>
        <template v-else-if="showReturnsAnalysis && !showAmortizationSchedule">
            <div class="text-yellow-500 text-center col-span-2 mb-3">Returns Analysis</div>
            <div class="grid grid-cols-2 gap-2 text-center">
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Purchase Price</span>
                    <span class="text-yellow-500">$ {{ purchasePrice | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Total Capital Required</span>
                    <span class="text-yellow-500">$ {{ totalCapitalRequired | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Net Operating Income</span>
                    <span class="text-yellow-500">$ {{ netOperatingIncome | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Debt Service</span>
                    <span class="text-yellow-500">$ {{ debtService | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Cash on Cash Return</span>
                    <span class="text-yellow-500">{{ cashOnCashReturn }}%</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-sm">
                    <span class="text-gray-500">Cap Rate</span>
                    <span class="text-yellow-500">{{ capRate.toFixed(2) }}%</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-md col-span-2">
                    <span class="text-gray-500">Cashflow</span>
                    <span class="text-yellow-500">$ {{ cashFlow.toFixed(2) | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col border-2 p-2 rounded bg-white text-md col-span-2">
                    <span class="text-gray-500">Principal Paydown</span>
                    <span class="text-yellow-500">$ {{ principalPaydown.toFixed(2) | thousandSeparator }}</span>
                </div>
                <div class="flex flex-col p-2 rounded bg-custom-blue text-lg col-span-2 mb-3">
                    <span class="text-white">Total Return on Investment</span>
                    <span class="text-yellow-500">$ {{ totalReturnOnInvestment | thousandSeparator }}</span>
                    <span class="text-yellow-500">{{ totalReturnOnInvestmentPercent.toFixed(2) }}%</span>
                </div>
            </div>
            <button class="btn btn-secondary mb-3" @click="showAmortizationSchedule = true">See Amortization Schedule</button>
            <button class="btn btn-secondary mb-3" @click="showReturnsAnalysis = false">Back To Form</button>
        </template>
        <template v-else>
            <div class="text-yellow-500 text-center col-span-2 mb-3">Amortization Schedule</div>
            <table class="table-auto bg-white rounded mb-3">
                <thead class="text-yellow-500 text-center text-sm rounded">
                    <tr>
                        <th>Month/Year</th>
                        <th>Payment Amount</th>
                        <th>Interest Paid</th>
                        <th>Principal Paid</th>
                        <th>Loan Balance</th>
                    </tr>
                </thead>
                <tbody class="text-center text-gray-500 text-xs">
                    <tr v-for="(value, index) in amortization" :key="index" :class="{'bg-blue-50' : index % 2 == 0}">
                        <td>{{ value.month }}</td>
                        <td>${{ value.paymentAmount | thousandSeparator }}</td>
                        <td>${{ value.interestPaid | thousandSeparator }}</td>
                        <td>${{ value.principalPaid | thousandSeparator }}</td>
                        <td>${{ value.loanBalance.toFixed(2) | thousandSeparator }}</td>
                    </tr>
                </tbody>
            </table>
            <button class="btn btn-secondary mb-3" @click="showAmortizationSchedule = false">Back To Returns Analysis</button>
        </template>
    </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
    data: () => ({
        showReturnsAnalysis: false,
        showAmortizationSchedule: false,
        purchasePrice: 0,
        costs: 0,
        downPaymentPercent: 0,
        closingCostsPercent: 0,
        yearsToPayoff: 0,
        interestRate: 0,
        rent: 0,
        utilities: 0,
        vacancyPercent: 0,
        maintenancePercent: 0,
        managementPercent: 0,
        yearlyUtilities: 0,
        additionalExpenses: 0,
        insurance: 0,
        taxes: 0,
        principalPaydown: 0,
        amortization: []
    }),
    filters: {
        thousandSeparator(value){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {
        downPayment(){
            return this.purchasePrice*(this.downPaymentPercent/100)
        },
        loan(){
            return this.purchasePrice-this.downPayment
        },
        closingCosts(){
            return (this.purchasePrice-this.downPayment)*(this.closingCostsPercent/100)
        },
        monthlyIncome(){
            return (((parseInt(this.rent) + parseInt(this.utilities))*12) - this.vacancy)
        },
        vacancy(){
            return ((parseInt(this.rent)+parseInt(this.utilities))*12)*(this.vacancyPercent/100)
        },
        maintenance(){
            return (((parseInt(this.rent) + parseInt(this.utilities))*12) - this.vacancy) * (this.maintenancePercent/100)
        },
        management(){
            return (((parseInt(this.rent) + parseInt(this.utilities))*12) - this.vacancy) * (this.managementPercent/100)
        },
        totalCapitalRequired(){
            return parseInt(this.downPayment)+parseInt(this.costs)+parseInt(this.closingCosts)
        },
        netOperatingIncome(){
            return parseFloat(this.monthlyIncome) - (parseFloat(this.maintenance)+parseFloat(this.management)+parseInt(this.yearlyUtilities)+parseInt(this.additionalExpenses)+parseInt(this.insurance)+parseInt(this.taxes))
        },
        paymentAmount(){
            let interest = this.loan*(this.interestRate/100)
            let divisor = 12 * (1-Math.pow((1+(this.interestRate/100)/12), -12*parseInt(this.yearsToPayoff)))
            let quotient = interest/divisor
            return quotient
        },
        debtService(){
            return (this.paymentAmount*12).toFixed(2)
        },
        cashOnCashReturn(){
            return ((this.cashFlow / this.totalCapitalRequired)*100).toFixed(2)
        },
        capRate(){
            return parseInt(this.netOperatingIncome)/parseInt(this.purchasePrice)*100
        },
        cashFlow(){
            return this.monthlyIncome-this.debtService-this.maintenance-this.management-this.yearlyUtilities-this.additionalExpenses-this.insurance-this.taxes
        },
        totalReturnOnInvestment(){
            return (this.cashFlow + this.principalPaydown).toFixed(2)
        },
        totalReturnOnInvestmentPercent(){
            return (this.totalReturnOnInvestment/this.totalCapitalRequired)*100
        }
    },
    methods: {
        computeAmortization(){
            this.amortization = []
            let noOfMonths = this.yearsToPayoff*12
            let interestRatePerMonth = (this.interestRate/100)/12
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            for(let i = 0; i < noOfMonths; i++){
                let interestPaid = i == 0 ? this.loan*interestRatePerMonth : this.amortization[i-1].loanBalance * interestRatePerMonth
                let principalPaid = this.paymentAmount - interestPaid
                let date = i == 0 ? dayjs() : dayjs().add(i, 'month')
                let details = {
                    month: `${months[date.month()]} ${date.year()}`,
                    paymentAmount: this.paymentAmount.toFixed(2),
                    interestPaid: interestPaid.toFixed(2),
                    principalPaid: principalPaid.toFixed(2),
                    loanBalance: i == 0 ? (this.loan - principalPaid) : (this.amortization[i-1].loanBalance - principalPaid) //loan balance value not rounded up here only on table display
                }

                //computation for principal paydown
                if(i == 12){
                    this.principalPaydown = this.amortization.reduce((prev, current) => parseFloat(prev) + parseFloat(current.principalPaid), 0)
                }

                this.amortization.push(details)
            }

            this.showReturnsAnalysis = true
        }
    }
}
</script>