<template>
	<div class="page-container">
		<router-link tag="div" class="flex rounded-lg bg-white h-20 px-5 pt-3 mb-3" v-for="(setting, index) in settings" :key="index" :to="`/settings/${setting.path}`">
			<div class="mr-2 mt-1 border-2 bg-gray-100 border-gray-100 rounded-lg text-custom-blue h-7">
				<span v-html="setting.icon"></span>
			</div>
			<div class="flex flex-col">
				<span class="text-yellow-500 font-semibold">{{ setting.name }}</span>
				<span class="text-gray-400 text-xs">{{ setting.desc }}</span>
			</div>
		</router-link>
	</div>
</template>
<script>
	export default{
		data: () => ({
			settings: [
				{
					path: 'properties', 
					name: 'Properties', 
					desc: 'List of your properties and let’s you add, edit or remove property',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
					</svg>`
				},
				{
					path: 'activities', 
					name: 'Activities', 
					desc: 'List of your activities and let’s you add, edit or remove your custom activity',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
					</svg>`
				},
				{
					path: 'notifications', 
					name: 'Notifications', 
					desc: 'Control for your notifications',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
					</svg>`
				},
				{
					path: 'change-password', 
					name: 'Change Password', 
					desc: 'Change your current password',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
					</svg>`
				}
			]
		}),
		methods: {
			
		},
		mounted(){
			
		}
	}
</script>