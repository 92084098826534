/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Mixins from './plugins/mixins'
import Maps from './plugins/googlemaps'
import router from './router'
import store from './store/index'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('app-component', require('./components/AppComponent.vue').default)
Vue.component('Header', require('./components/Header.vue').default)
Vue.component('Loading', require('./components/reusables/Loading.vue').default)
Vue.component('ImagePreview', require('./components/reusables/ImagePreview.vue').default)
Vue.component('ConfirmationDialog', require('./components/reusables/ConfirmationDialog.vue').default)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.prototype.$isOverlayLoading = false

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    store,
    mixins: Mixins,
    Maps
})
