<template>
	<div class="page-container">
		<div class="grid grid-cols-2 gap-4">
			<router-link tag="div" v-for="(menu, index) in menus" class="grid grid-cols-2 border-2 bg-white border-gray-50 h-36 w-full rounded-xl shadow cursor-pointer" :key="index" :to="menu.path">
				<div class="flex ml-3 mt-3 h-16 w-16 bg-indigo-50 rounded-3xl">
					<img class="m-auto" :src="`../storage/img/${menu.iconSrc}`">
				</div>
				<div class="font-bold text-4xl w-full mt-1 text-gray-200 pr-2">
					<span class="float-right">{{ menu.infoVal }}</span>
				</div>
				<div class="flex flex-col ml-3 col-span-2">
					<span class="text-yellow-500 font-bold text-sm">{{ menu.name }}</span>
					<span class="text-gray-500 text-xs" v-if="menu.hasOwnProperty('infoLabel')">{{ menu.infoLabel }}: {{ menu.infoVal }} </span>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return {
				menus: [
					{
						path: '/time-log', 
						name: 'Time Log', 
						iconSrc: 'timelog.svg',
						infoLabel: 'Week Hours',
						infoVal: '10'
					},
					{
						path: '/gps-mile-tracker', 
						name: 'GPS Mile Tracker', 
						iconSrc: 'gpsmiletracker.svg',
						infoLabel: 'Week Miles',
						infoVal: '30'
					},
					{
						path: '/reports', 
						name: 'Reports', 
						iconSrc: 'reports.svg'
					},
					{
						path: '/receipt-manager', 
						name: 'Receipt Manager', 
						iconSrc: 'receiptmanager.svg',
						infoLabel: 'Total Receipts',
						infoVal: '25'
					},
					{
						path: '/calculators', 
						name: 'Calculators', 
						iconSrc: 'calculator.svg'
					},
					{
						path: '/tools', 
						name: 'Tools', 
						iconSrc: 'tools.svg'
					}
				]
			}
		},
		methods: {
			
		},
		mounted(){
			
		}
	}
</script>