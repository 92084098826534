<template>
	<div class="page-container flex flex-col">
		<!-- breadcrumbs -->
		<div class="flex flex-wrap w-full text-sm">
			<div v-for="(breadcrumb, index) in breadCrumbs" :key="index">
				<template v-if="breadCrumbs.length != index+1">
					<div class="flex mb-2">
						<router-link class="text-yellow-500" :to="`/receipt-manager/${breadcrumb.folder_id}`">{{ breadcrumb.folder_name }}</router-link>
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 pt-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
						</svg>
					</div>
				</template>
				<template v-else>
					<span>{{ breadcrumb.folder_name }}</span>
				</template>
			</div>
		</div>
		<!-- content -->
		<div class="grid grid-cols-3 gap-2">
			<router-link tag="div" class="container flex flex-col content-center" v-for="(folder, index) in folders" :key="`fldr${index}`" :to="`/receipt-manager/${folder.id}`">
				<img :src="'../storage/img/folder.svg'" class="p-0" width="100%" alt="">
				<span class="text-yellow-500 text-xs font-light text-center">{{ folder.name }}</span>
			</router-link>
			<div v-for="(attachment, index) in attachments" :key="`img${index}`" @click="showPreview(attachment.file_url, attachment.id, attachment.original_file_name)">
				<img :src="attachment.file_url" alt="" loading="lazy">
				<span class="text-yellow-500 text-xs font-light text-center">{{ attachment.original_file_name }}</span>
			</div>
		</div>
		<button class="btn-fab" @click="dialog = true">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
		</button>
		<ImagePreview :image="image" v-if="isShowPreview" @close="isShowPreview = false" />
		<!-- menu -->
		<div class="flex dialog-background" v-if="dialog">
			<button class="absolute top-0 right-0 mr-3 mt-3 border-white border-2 rounded" @click="dialog = false">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</button>
			<div class="flex flex-col space-y-5 bg-white text-gray-500 m-auto rounded h-auto w-3/4 md:w-60 px-3 py-5">
				<template v-if="showMenu">
					<div class="inline-flex cursor-pointer" @click="toggleForm('folder')">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
						</svg>
						<span class="text-yellow-500">Folder</span>
					</div>
					<hr>
					<div class="inline-flex" @click="toggleForm('photos')">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
						</svg>
						<span class="text-yellow-500">Photos</span>
					</div>
				</template>
				<template v-if="showFolderForm">
					<div class="custom-text-field my-auto">
						<input name="folder" id="folder" type="text" placeholder=" " v-model="folderName" autocomplete="nope" />
						<label for="folder">Folder Name</label>
					</div>
					<div class="flex justify-end">
						<button @click="toggleForm('menu')">Cancel</button>
						<button class="inline-flex text-white p-2 rounded bg-custom-blue ml-5" @click="addFolder">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
							</svg>
							Add
						</button>
					</div>
				</template>
				<template v-if="showUploadForm">
					<div class="border-2 text-center rounded bg-white py-3 text-yellow-500">
						<input @change="setFileUploads" name="attachment" id="attachment" type="file" hidden multiple accept="image/*" />
						<label for="attachment" class="w-full flex justify-center h-full">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
							</svg>
							Attachments
						</label>
						<span class="text-xs">
							{{ imageUploads.length }}
							<template v-if="imageUploads.length <= 1">file</template>
							<template v-else>files</template>
						</span>
					</div>
					<div class="flex justify-end">
						<button @click="toggleForm('menu')">Cancel</button>
						<button class="inline-flex text-white p-2 rounded bg-custom-blue ml-5" @click="uploadImages">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
							</svg>
							Upload
						</button>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapGetters } from 'vuex'

	export default{
		data: () => ({
			isLoading: false,
			dialog: false,
			showMenu: true,
			showFolderForm: false,
			showUploadForm: false,
			folderName: '',
			imageUploads: [],
			image: {
				url: '',
				id: '',
				name: ''
			},
			isShowPreview: false
		}),
		computed: {
			...mapState({
				folders: state => state.receiptManagerModule.folders,
				attachments: state => state.receiptManagerModule.attachments,
				breadCrumbs: state => state.receiptManagerModule.breadCrumbs
			})
		},
		watch: {
			$route(to, from){
				if(to != from){
					this.$router.go()
				}
			}
		},
		methods: {
			toggleForm(form){
				switch(form){
					case 'folder':
						this.showFolderForm = true
						this.showUploadForm = false
						this.showMenu = false
						break;
					case 'photos':
						this.showFolderForm = false
						this.showUploadForm = true
						this.showMenu = false
						break;
					case 'menu':
						this.showFolderForm = false
						this.showUploadForm = false
						this.showMenu = true
						break;
					default:
				}
			},
			async getContents(){
				await this.$store.dispatch('fetchFolders', this.$route.params.id)
				await this.$store.dispatch('fetchAttachments', this.$route.params.id)
				await this.$store.dispatch('fetchFoldersTree', this.$route.params.id)
			},
			async addFolder(){
				await this.$store.dispatch('addFolder', { parent_id: this.$route.params.id, folder_name: this.folderName})
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Added folder'
				})
				this.isLoading = true
				this.dialog = false
				await this.getContents()
				this.isLoading = false
				this.toggleForm('menu')
			},
			async uploadImages(){
				this.isLoading = true
				this.dialog = false
				await this.$store.dispatch('upload', { images: this.imageUploads, attachmentFolderId: this.$route.params.id})
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Uploaded attachment/s'
				})
				await this.getContents()
				this.isLoading = false
				this.toggleForm('menu')
			},
			showPreview(imageUrl, imageId, imageName){
				this.image.url = imageUrl
				this.image.id = imageId
				this.image.name = imageName
				this.isShowPreview = true
			},
			setFileUploads(evt){
				this.imageUploads = evt.target.files
			}
		},
		async mounted(){
			this.isLoading = true
			await this.getContents()
			this.isLoading = false
		}
	}
</script>