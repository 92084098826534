<template>
    <div class="bg-white w-full p-2">
        <div class="my-2 inline-flex" v-if="folderTree.parent_folder_name == null">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <span class="text-yellow-500">Receipt Manager</span>
        </div>
        <div class="my-2 inline-flex cursor-pointer" v-else @click="goToFolder(folderTree.parent_id)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
                <span class="text-yellow-500">{{ folderTree.parent_folder_name }}</span>
            </div>
        <hr class="my-2">
        <template v-if="folderTree.data.length != 0">
            <div v-for="(folder, index) in folderTree.data" :key="index" class="flex">
                <div class="inline-flex mb-2 cursor-pointer" @click="goToFolder(folder.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                    </svg>
                    <span class="text-yellow-500">{{ folder.name }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </div>
            </div>
        </template>
        <template v-else>
            <span>No Folders</span>
        </template>
        <div class="flex justify-end">
            <button class="btn text-white bg-custom-blue" @click="moveFolder">Move Here</button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: [
        'id'
    ],
    computed: {
        ...mapState({
            folderTree: state => state.receiptManagerModule.folderTree
        })
    },
    methods: {
        async goToFolder(folderId){
            await this.$store.dispatch('fetchFoldersTree', folderId)
        },
        async moveFolder(){
            await this.$store.dispatch('moveAttachmentFolder', {folderId: this.folderTree.folder_id, attachmentId: this.id})
            this.$store.commit('SET_SNACKBAR_MSG', {
                isActive: true,
                text: 'Moved attachment folder'
            })
            this.$router.go()
        },
    }
}
</script>