export default {
	state: {
		folders: [],
		attachments: [],
		folderTree: [],
		breadCrumbs: []
	},
	mutations: {
		SET_FOLDERS(state, folders){
			state.folders = folders
		},
		SET_ATTACHMENTS(state, payload){
			state.attachments = payload.data
			state.breadCrumbs = payload.meta.breadcrumbs
		},
		SET_FOLDER_TREE(state, folderTree){
			state.folderTree = folderTree
		}
	},
	getters: {
		
	},
	actions: {
		async fetchFolders({commit}, parentId){
			let url = (parentId == null) ? `${this.state.endpoint}/attachment-folders` : `${this.state.endpoint}/attachment-folders/${parentId}`
			const response = await axios.get(url)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_FOLDERS', response.data.data)
		},
		async fetchFoldersTree({commit}, parentId){
			let url = (parentId == null) ? `${this.state.endpoint}/attachment-folders` : `${this.state.endpoint}/attachment-folders/${parentId}`
			const response = await axios.get(url)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_FOLDER_TREE', response.data)
		},
		async fetchAttachments({commit}, attachmentFolderId){
			let url = (attachmentFolderId == null) ? `${this.state.endpoint}/receipt-manager-attachments` : `${this.state.endpoint}/receipt-manager-attachments/${attachmentFolderId}`
			const response = await axios.get(url)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_ATTACHMENTS', response.data)
		},
		async addFolder({commit}, folderData){
			await axios.post(`${this.state.endpoint}/attachment-folder`, folderData)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async upload({commit}, payload){
			let data = new FormData()
			Array.from(payload.images).forEach(function(img){
				data.append('attachments[]', img, img.name)
			})
			if(payload.attachmentFolderId !== null && payload.attachmentFolderId !== undefined){
				data.append('attachment_folder_id', payload.attachmentFolderId)
			}
			const response = await axios.post(`${this.state.endpoint}/receipt-manager`, data, {
				headers: {
					'accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8',
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				}
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async deleteReceiptManagerAttachment({commit}, attachmentId){
			await axios.delete(`${this.state.endpoint}/receipt-manager/${attachmentId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async moveAttachmentFolder({commit}, payload){
			await axios.patch(`${this.state.endpoint}/receipt-manager/${payload.attachmentId}/${payload.folderId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		}
	}
}