export default {
	state: {
		reports: [],
		reportsMeta: [],
		queryParams: ''
	},
	mutations: {
		SET_REPORTS(state, payload){
			state.reports = payload.reports
			state.reportsMeta = payload.meta
			state.queryParams = payload.queryParams
		}
	},
	getters: {

	},
	actions: {
		async fetchTimeLogReports({commit}, params){
			let url = `${this.state.endpoint}/reports/time-log`
			let queryParams = `?filter=${params.filter}`
			if(params.filter == 'custom'){
				if(params.date != null){
					queryParams += `&dateFrom=${params.date.start}`
					if(params.date.end == null){
						queryParams += `&dateTo=${params.date.start}`
					}
					else{
						queryParams += `&dateTo=${params.date.end}`
					}
				}
				if(params.property != 'all'){
					queryParams += `&property=${params.property}`
				}
				if(params.activity != 'all'){
					queryParams += `&activity=${params.activity}`
				}
			}
			else if(params.filter == 'latest'){
				queryParams += `&limit=${params.limit}`
			}

			await axios.get(`${url}${queryParams}`)
			.then(response => {
				commit('SET_REPORTS', {
					reports: response.data.data,
					meta: response.data.meta,
					queryParams: queryParams
				})
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async fetchGpsMileTrackerReports({commit}, params){
			let url = `${this.state.endpoint}/reports/gps-mile-tracker?`
			let queryParams = ''
			if(params.date != null){
				queryParams += `dateFrom=${params.date.start}`
				if(params.date.end == null){
					queryParams += `&dateTo=${params.date.start}`
				}
				else{
					queryParams += `&dateTo=${params.date.end}`
				}
			}

			if(params.tripType != 'all'){
				queryParams += params.date == null ? `type=${params.tripType}` : `&type=${params.tripType}`
			}

			await axios.get(`${url}${queryParams}`)
			.then(response => {
				commit('SET_REPORTS', {
					reports: response.data.data,
					meta: response.data.meta,
					queryParams: queryParams
				})
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async sendReportToEmail({commit, state}, payload){
			await axios.post( `${this.state.endpoint}/reports/send-to-email?${state.queryParams}`, payload)
			.then(response => {
				commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: response.data.message
				})
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		}
	}
}