export default {
	state: {
		timeLog: [],
		timeLogs: [],
		timerStatus: {
			hasOngoingTimer: false,
			ongoingTimerId: ''
		}
	},
	mutations: {
		SET_TIMELOG(state, timeLog){
			state.timeLog = timeLog
			state.timeLog.files = [] //for counting of files
		},
		SET_TIMELOGS(state, timeLogs){
			state.timeLogs = timeLogs
		},
		SET_TIMERS(state, timers){
			state.timeLog.timer = timers
		},
		SET_ONGOING_TIMER(state, payload){
			state.timerStatus.hasOngoingTimer = payload.hasOngoingTimer
			state.timerStatus.ongoingTimerId = payload.timerId
		}
	},
	getters: {
		eventList(state){
			const events = state.timeLogs.map(timeLog => {
				const container = {}

				container['id'] = timeLog.id
				container['title'] = timeLog.property
				container['start'] = timeLog.start_time
				container['end'] = timeLog.end_time
				container['allDay'] = false
				
				return container
			})

			return events
		},
		timeLog(state){
			if(state.timeLog.length != 0){
				let startTime = state.timeLog.start_time.split(" ")
				let endTime = state.timeLog.end_time.split(" ")
				state.timeLog['start_time'] = startTime[1]
				state.timeLog['end_time'] = endTime[1]
				state.timeLog['date'] = { start: startTime[0], end: endTime[0] } 
				state.timeLog['start_hour'] = (endTime[1] != '23:23:59') ? startTime[1] : '--'
				state.timeLog['end_hour'] = (endTime[1] != '23:23:59') ? endTime[1] : '--'
				state.timeLog['allDay'] = (endTime[1] != '23:23:59') ? false : true
			}
			return state.timeLog
		}
	},
	actions: {
		async getTimeLog({commit}, timeLogId){
			const response = await axios.get(`${this.state.endpoint}/time-log/${timeLogId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_TIMELOG', response.data.data)
			commit('SET_ONGOING_TIMER', response.data.meta)
		},
		async getTimeLogs({commit}){
			const response = await axios.get(`${this.state.endpoint}/time-logs`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_TIMELOGS', response.data.data)
		},
		async addTimeLog({commit}, payload){
			let data = new FormData()

			data.append('property_id', payload.property_id)
			data.append('activity_id', payload.activity_id)
			data.append('note', payload.note)
			data.append('start_time', payload.start_time)
			data.append('end_time', payload.end_time)
			data.append('total_hours', payload.total_hours)
			data.append('is_real_estate_related_activity', payload.is_real_estate_related_activity == true ? 1 : 0)
			if(payload.hasOwnProperty('files')){
				Array.from(payload.files).forEach(function(file){
					data.append('attachments[]', file, file.name)
				})
			}

			const response = await axios.post(`${this.state.endpoint}/time-log`, data, {
				headers: {
					'accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8',
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`
				}
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async updateTimeLog({commit}, payload){
			if(payload.hasOwnProperty('images')){
				let data = new FormData()

				data.append('id', payload.id)
				data.append('property_id', payload.property_id)
				data.append('activity_id', payload.activity_id)
				data.append('note', payload.note)
				data.append('start_time', payload.start_time)
				data.append('end_time', payload.end_time)
				data.append('total_hours', payload.total_hours)
				data.append('is_real_estate_related_activity', payload.is_real_estate_related_activity == true ? 1 : 0)
				payload.images.forEach(function(img){
					data.append('attachments[]', img, img.name)
				})
				data.append('_method', 'PATCH')

				const response = await axios.post(`${this.state.endpoint}/time-log/${payload.id}`, data, {
					headers: {
						'accept': 'application/json',
						'Accept-Language': 'en-US,en;q=0.8',
						'Content-Type': `multipart/form-data; boundary=${data._boundary}`
					}
				})
				.catch(error => {
					if(error.response.status == 401){
						this.dispatch('redirectToLogin')
					}
				})
			}
			else{
				const response = await axios.patch(`${this.state.endpoint}/time-log/${payload.id}`, {
					property_id: payload.property_id,
					activity_id: payload.activity_id,
					is_real_estate_related_activity: payload.is_real_estate_related_activity == true ? 1 : 0,
					note: payload.note,
					total_hours: payload.total_hours,
					start_time: payload.start_time,
					end_time: payload.end_time
				})
				.catch(error => {
					if(error.response.status == 401){
						this.dispatch('redirectToLogin')
					}
				})
			}
		},
		async deleteTimeLog({commit}, timeLogId){
			await axios.delete(`${this.state.endpoint}/time-log/${timeLogId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async deleteTimeLogAttachment({commit}, timelogAttachmentId){
			await axios.delete(`${this.state.endpoint}/time-log-attachment/${timelogAttachmentId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async startTimer({commit}, timeLogId){
			const response = await axios.post(`${this.state.endpoint}/timer`, { time_log_id: timeLogId })
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			const timers = await axios.get(`${this.state.endpoint}/timer-log/${timeLogId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_TIMERS', timers.data.data)
			commit('SET_ONGOING_TIMER', {
				hasOngoingTimer: true,
				timerId: response.data.data.id
			})
		},
		async stopTimer({commit}, payload){
			await axios.patch(`${this.state.endpoint}/timer/${payload.timerId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			const timers = await axios.get(`${this.state.endpoint}/timer-log/${payload.timeLogId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_TIMERS', timers.data.data)
			commit('SET_ONGOING_TIMER', {
				hasOngoingTimer: false,
				timerId: null
			})
		}
	}
}