export default {
	state: {
		states: [],
		cities: []
	},
	mutations: {
		SET_STATES(state, states){
			state.states = states
		},
		SET_CITIES(state, cities){
			state.cities = cities
		}
	},
	getters: {

	},
	actions: {
		async fetchStates({commit}){
			const response = await axios.get('/api/v1/states')
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_STATES', response.data.data)
		},
		async fetchCitiesByState({commit}, stateId){
			const response = await axios.get(`/api/v1/cities/${stateId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_CITIES', response.data.data)
		}
	}
}