export default {
	state: {
		notificationSettings: []
	},
	mutations: {
		SET_NOTIFICATION_SETTINGS(state, notificationSettings){
			state.notificationSettings = notificationSettings
		}
	},
	getters: {

	},
	actions: {
		async changePassword({commit}, passwords){
			await axios.patch(`${this.state.endpoint}/change-password`, {
				current_password: passwords.currentPassword,
                new_password: passwords.newPassword,
                new_password_confirmation: passwords.confirmNewPassword
			}).then(response => {
                if(response.data.message == 'Changed password'){
                    this.commit('SET_SNACKBAR_MSG', {
                        isActive: true,
                        text: response.data.message
                    })
                    this.dispatch('redirectToLogin')
                }
                else if(response.data.message == 'Incorrect password'){
                    this.commit('SET_SNACKBAR_MSG', {
                        isActive: true,
                        text: response.data.message
                    })
                }
			}).catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async sendForgotPasswordLink({commit}, email ){
			await axios.post(`${this.state.endpoint}/send-reset-password-link`, {
				email: email
			}).then(response => {
				this.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: response.data.message
				})
			}).catch(error => {
				this.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: ''
				})
			})
		},
		async resetPassword({commit}, accountInfo){
			await axios.patch(`${this.state.endpoint}/reset-password/${accountInfo.token}`, {
				new_password: accountInfo.new_password,
				new_password_confirmation: accountInfo.new_password_confirmation,
				email: accountInfo.email
			}).then(response => {
				this.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: response.data.message
				})
				if(response.status == 200){
					this.dispatch('redirectToLogin')
				}
			}).catch(error => {
				this.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: error.response.data
				})
			})
		}
	}
}