<template>
	<div class="page-container">
		<div class="custom-text-area">
			<textarea name="origin" id="origin" v-model="trip.origin_loc"></textarea>
			<label for="origin">Origin</label>
		</div>
		<div class="custom-text-area">
			<textarea name="destination" id="destination" v-model="trip.destination_loc"></textarea>
			<label for="destination">Destination</label>
		</div>
		<div class="flex space-x-2">
			<div class="custom-text-field flex-1">
				<input name="start_time" id="start_time" type="text" placeholder=" " v-model="trip.start_time" />
				<label for="start_time">Start Time</label>
			</div>
			<div class="custom-text-field flex-1">
				<input name="end_time" id="end_time" type="text" placeholder=" " v-model="trip.end_time" />
				<label for="end_time">End Time</label>
			</div>
		</div>
		<div class="flex space-x-2">
			<div class="custom-text-field flex-1">
				<input name="distance" id="distance" type="text" placeholder=" " v-model="trip.distance" />
				<label for="distance">Distance</label>
			</div>
			<div class="custom-text-field flex-1">
				<input name="total_hours" id="total_hours" type="text" placeholder=" " v-model="trip.total_hours" />
				<label for="total_hours">Total Hours</label>
			</div>
		</div>
		<div class="custom-select">
			<select name="type" id="type" type="text" v-model="trip.type">
				<option :value="type.type" v-for="(type, index) in types" :key="index">{{ type.text }}</option>
			</select>
			<label for="type">Type</label>
		</div>
		<template v-if="trip.type == 2">
			<div class="custom-select">
				<select name="properties" id="properties" type="text" v-model="trip.property_id">
					<option :value="property.property_id" v-for="(property, index) in properties" :key="index">{{ property.property }}</option>
				</select>
				<label for="properties">Properties</label>
			</div>
			<div class="custom-select">
				<select name="activities" id="activities" type="text" v-model="trip.activity_id">
					<option :value="activity.id" v-for="(activity, index) in activities" :key="index">{{ activity.activity }}</option>
				</select>
				<label for="activities">Activities</label>
			</div>
		</template>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="updateType">Update</button>
			<div class="flex space-x-1" v-if="trip.status == 'ONGOING'">
				<button class="btn btn-secondary mb-3 flex-1" @click="showConfirmationDialog = end = true">End</button>
				<button class="btn btn-secondary mb-3 flex-1" @click="showConfirmationDialog = true">Cancel</button>
			</div>
		</div>
		<ConfirmationDialog v-if="showConfirmationDialog">
			<template v-slot:message>
				<span v-if="end">You want to end your trip?</span>
				<span v-else>You want to cancel your trip?</span>
			</template>
			<template v-slot:action>
				<button @click="endTrip" v-if="end">OK</button>
				<button @click="cancelTrip" v-else>OK</button>
				<button @click="showConfirmationDialog = false">Cancel</button>
			</template>
		</ConfirmationDialog>
	</div>
</template>
<script>
	import { mapState } from 'vuex'

	export default{
		data: () => ({
			types: [
				{ type: 1, text: 'Personal' },
				{ type: 2, text: 'Business' }
			],
			isLoading: false,
			trip: [],
			showConfirmationDialog: false,
			end: false
		}),
		computed: {
			...mapState({
				properties: state => state.propertyModule.properties,
				activities: state => state.activityModule.activities,
			})
		},
		methods: {
			getSelectedType(type){
				this.trip.type = type
			},
			async getTrip(){
				await this.$store.dispatch('fetchTrip', this.$route.params.id)
				this.trip = this.$store.getters.trip
			},
			async updateType(){
				this.isLoading = true
				await this.$store.dispatch('updateType', {
					gpsMileTrackerId: this.$route.params.id,
					typeId: this.trip.type,
					propertyId: this.trip.type == 2 ? this.trip.property_id : null,
					activityId: this.trip.type == 2 ? this.trip.activity_id : null,
					startTime: this.trip.type == 2 ? this.trip.start_time : null,
					endTime: this.trip.type == 2 ? this.trip.end_time : null,
					totalHours: this.trip.type == 2 ? this.trip.total_hours: null
				})
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Updated Trip Type'
				})
				this.isLoading = false
				this.$router.push('/gps-mile-tracker')
			},
			async endTrip(){
				this.showConfirmationDialog = false
				this.isLoading = true
				this.end = false

				await this.$store.dispatch('endTrip', {
					gpsMileTrackerId: this.$route.params.id
				})

				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Ended your trip'
				})

				this.isLoading = false
				this.$router.push('/gps-mile-tracker')
			},
			async cancelTrip(){
				this.showConfirmationDialog = false
				this.isLoading = true
				await this.$store.dispatch('cancelTrip', {
					gpsMileTrackerId: this.$route.params.id
				})

				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Cancelled your trip'
				})

				this.isLoading = false
				this.$router.push('/gps-mile-tracker')
			},
			async getProperties(){
				await this.$store.dispatch('fetchProperties')
			},
			async getActivities(){
				await this.$store.dispatch('fetchActivities')
			},
		},
		async mounted(){
			this.isLoading = true
			await this.getTrip()
			await this.getProperties()
			await this.getActivities()
			this.isLoading = false
		}
	}
</script>