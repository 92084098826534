<template>
	<div class="container bg-dark-blue h-screen max-w-full">
		<div class="container absolute inset-x-0 bottom-0 h-3/4 bg-white pt-14 rounded-tl-3xl max-w-full">
			<div class="flex flex-col mx-5">
				<div class="my-4 border-b-2 focus-within:border-blue-500">
					<input name="newPassword" class="block w-full appearance-none focus:outline-none bg-transparent text-gray-800" type="password" v-model="passwordForm.newPassword" placeholder="New Password" />
				</div>
				<div class="my-4 border-b-2 focus-within:border-blue-500">
					<input name="confirmPassword" class="block w-full appearance-none focus:outline-none bg-transparent text-gray-800" type="password" v-model="passwordForm.confirmNewPassword" placeholder="Confirm Password" />
				</div>
				<div class="grid justify-items-end">
					<a type="submit" class="btn btn-primary mt-5 mb-5" @click="resetPassword">Reset Password</a>
				</div>
			</div>
			<div class="absolute bottom-0 text-center w-full mb-2 text-gray-500 text-sm">
				Remember password? <router-link class="text-yellow-500" to="/">Login</router-link>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data: () => ({
            isLoading: false,
            passwordForm: {
                newPassword: '',
                confirmNewPassword: ''
            },
			requiredRules: [
				v => !!v || 'This field is required'
			]
		}),
		computed: {

		},
		methods: {
			async resetPassword(){
				this.isLoading = true
				await this.$store.dispatch('resetPassword', {
					token: this.$route.params.token,
					email: this.$route.query.email,
					new_password: this.passwordForm.newPassword,
					new_password_confirmation: this.passwordForm.confirmNewPassword
				})
				this.isLoading = false
            }
		},
		mounted(){

		}
	}
</script> 