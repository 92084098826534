<template>
	<div class="container bg-dark-blue h-screen max-w-full">
		<div class="container absolute inset-x-0 bottom-0 h-3/4 bg-white pt-14 rounded-tl-3xl max-w-full">
			<div class="flex flex-col mx-5">
				<div class="text-center w-full">
					<span class="text-custom-blue font-bold">Forgot Password</span>
				</div>
				<div class="text-center w-full mb-10">
					<span class="text-gray-500 text-xs">Enter your registered email below to receive password reset instruction</span>
				</div>
				<div class="my-4 border-b-2 focus-within:border-blue-500">
					<input name="email" class="block w-full appearance-none focus:outline-none bg-transparent text-gray-800" type="text" v-model="email" placeholder="Email" />
				</div>
				<div class="grid justify-items-end">
					<a type="submit" class="btn btn-primary mt-5 mb-5" @click="sendLink">Send link</a>
				</div>
			</div>
			<div class="absolute bottom-0 text-center w-full mb-2 text-gray-500 text-sm">
				Remember password? <router-link class="text-yellow-500" to="/">Login</router-link>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data: () => ({
            isLoading: false,
            email: ''
		}),
		computed: {

		},
		methods: {
			async sendLink(){
				this.isLoading = true
				await this.$store.dispatch('sendForgotPasswordLink', this.email)
				this.isLoading = false
            }
		},
		mounted(){

		}
	}
</script> 