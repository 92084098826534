<template>
	<div class="page-container">
		<div class="custom-text-area-tall">
			<textarea name="activity" id="activity" type="text" placeholder=" " v-model="activity.activity" autocomplete="nope"></textarea>
			<label for="activity">Activity</label>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="save">Update</button>
			<button class="btn btn-secondary" @click="showConfirmationDialog = true">Delete</button>
		</div>
		<ConfirmationDialog v-if="showConfirmationDialog">
			<template v-slot:message>
				Are you sure you want to delete?
			</template>
			<template v-slot:action>
				<button @click="deleteActivity">OK</button>
				<button @click="showConfirmationDialog = false">Cancel</button>
			</template>
		</ConfirmationDialog>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'

	export default{
		data: () => ({
			isLoading: false,
			showConfirmationDialog: false
		}),
		computed: {
			...mapState({
				activity: state => state.activityModule.activity
			})
		},
		methods: {
			async getActivity(){
				this.isLoading = true
				await this.$store.dispatch('fetchActivity', this.$route.params.id)
				this.isLoading = false
			},
			async save(){
				this.isLoading = true
				await this.$store.dispatch('editActivity', this.activity)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Updated activity'
				})
				this.isLoading = false
				this.$router.replace('/settings/activities')
			},
			async deleteActivity(){
				this.isLoading = true
				await this.$store.dispatch('deleteActivity', this.$route.params.id)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Deleted activity'
				})
				this.isLoading = false
				this.$router.replace('/settings/activities')
			},
			showConfirmation(){
				this.showConfirmationDialog = true
			}
		},
		mounted(){
			this.getActivity()
		}
	}
</script> 