<template>
	<div class="flex dialog-background">
		<!-- modal -->
		<div class="bg-white text-gray-500 m-auto rounded h-auto sm:w-3/4 md:w-60 p-2">
			<div class="mx-2">
				<span>
					<slot name="message"></slot>
				</span>
			</div>
			<div class="flex justify-between px-5 mb-2 mt-5">
				<slot name="action"></slot>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		
	}
</script>