export default {
	state: {
		trips: [],
		trip: [],
		ongoingTrip: {
			hasOngoingTrip: false,
			hasDestination: false,
			ongoingGpsMileTrackerId: '',
			destinationCoordinates: ''
		},
		meta: {
			currentPage: 1,
			lastPage: 0
		},
		origin: {
			coordinates: {
				lat: '',
				lng: ''
			},
			address: '',
			placeId: ''
		},
		currentLocation: {
			coordinates: {
				lat: '',
				lng: ''
			},
			address: '',
			placeId: ''
		},
		distance: '',
		logs: [
			{
				text: 'Logs started',
				timestamp: '00:00:00:000'
			}
		]
	},
	mutations: {
		SET_TRIPS(state, payload){
			if(payload.isLoadMore){
				state.trips = [...state.trips, ...payload.trips]
			}
			else{
				state.trips = payload.trips
			}
		},
		SET_TRIP(state, trip){
			state.trip = trip
		},
		SET_ONGOING_TRIP(state, payload){
			state.ongoingTrip.hasOngoingTrip = payload.hasOngoingTrip
			state.ongoingTrip.hasDestination = payload.hasDestination
			state.ongoingTrip.ongoingGpsMileTrackerId = payload.id
			state.ongoingTrip.destinationCoordinates = payload.destinationCoordinates
		},
		SET_META(state, payload){
			state.meta.currentPage = payload.current_page
			state.meta.lastPage = payload.last_page
		},
		SET_ORIGIN_COORDINATES(state, payload){
			state.origin.coordinates.lng = payload.longitude
			state.origin.coordinates.lat = payload.latitude
		},
		SET_CURRENT_LOCATION_COORDINATES(state, payload){
			state.currentLocation.coordinates.lng = payload.longitude
			state.currentLocation.coordinates.lat = payload.latitude
		},
		SET_ORIGIN_DETAILS(state, payload){
			state.origin.address = payload.address,
			state.origin.placeId = payload.placeId
		},
		SET_CURRENT_LOCATION_DETAILS(state, payload){
			state.currentLocation.address = payload.address,
			state.currentLocation.placeId = payload.placeId
		},
		SET_DISTANCE(state, distance){
			state.distance = distance
		},
		SET_LOGS(state, log){
			state.logs.push({
				text: log.text,
				timestamp: log.timestamp
			})
		}
	},
	getters: {
		trip(state){
			return state.trip
		}
	},
	actions: {
		async fetchTrips({commit}, payload){
			let rowsPerPage = 10
			await axios.get(`${this.state.endpoint}/gps-mile-trackers?page=${payload.page}&rowsPerPage=${rowsPerPage}&filter=${payload.filter}`)
			.then(response => {
				commit('SET_TRIPS', {
					trips: response.data.data,
					isLoadMore: payload.isLoadMore
				})
				commit('SET_META', response.data.meta)
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async fetchTrip({commit}, gpsMileTrackerId){
			const response = await axios.get(`${this.state.endpoint}/gps-mile-tracker/${gpsMileTrackerId}`)
			.then(response => {
				commit('SET_TRIP', response.data.data)
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async fetchOngoingTrip({commit}){
			await axios.get(`${this.state.endpoint}/gps-mile-tracker/ongoing-trip`)
			.then(response => {
				commit('SET_ONGOING_TRIP', response.data)
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async startTrip({}, payload){
			await axios.post(`${this.state.endpoint}/gps-mile-tracker`, payload)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async endTrip({}, payload){
			await axios.patch(`${this.state.endpoint}/gps-mile-tracker/${payload.gpsMileTrackerId}`, payload.destination)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async cancelTrip({}, payload){
			await axios.delete(`${this.state.endpoint}/gps-mile-tracker/${payload.gpsMileTrackerId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async updateType({}, payload){
			await axios.patch(`${this.state.endpoint}/gps-mile-tracker/${payload.gpsMileTrackerId}/${payload.typeId}`, {
				property_id: payload.propertyId,
				activity_id: payload.activityId,
				start_time: payload.startTime,
				end_time: payload.endTime,
				total_hours: payload.totalHours
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		setOriginCoordinates({commit}, coordinates){
			commit('SET_ORIGIN_COORDINATES', coordinates)
		},
		setCurrentLocationCoordinates({commit}, coordinates){
			commit('SET_CURRENT_LOCATION_COORDINATES', coordinates)
		},
		async getAddress({commit}, payload){
			var instance = axios.create({})

			delete instance.defaults.headers.common['X-CSRF-TOKEN']
			delete instance.defaults.headers.common['X-Requested-With']
			delete instance.defaults.headers.common['Authorization']

			await instance.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${payload.coordinates.latitude},${payload.coordinates.longitude}&key=AIzaSyBYAVxHVuj9uMNr4sr4Rwy8v4v38I6KndQ`, {
				headers: {
					'Accept-Language': 'en;q=0.8'
				}
			}).then(response => {
				if(payload.point == 'origin'){
					commit('SET_ORIGIN_DETAILS', {
						address: response.data.results[0].formatted_address,
						placeId: response.data.results[0].place_id
					})
				}
				else{
					commit('SET_CURRENT_LOCATION_DETAILS', {
						address: response.data.results[0].formatted_address,
						placeId: response.data.results[0].place_id
					})
				}
				
			})
		},
		async getDistance({state, commit}, placeIds){
			//used google maps javascript api for fetching distance matrix since it is said it doesn't support xmlhttprequest and triggers cors as of this writing
			return new Promise((resolve, reject) => {
				var service = new google.maps.DistanceMatrixService()
				service.getDistanceMatrix({
						origins: [{ placeId: placeIds.origin }],
						destinations: [{ placeId: placeIds.destination }],
						travelMode: google.maps.TravelMode.DRIVING,
						unitSystem: google.maps.UnitSystem.METRIC,
						avoidHighways: false,
						avoidTolls: false
					},
					(response, status) => {
						if(status === "OK"){
							commit('SET_DISTANCE', (response.rows[0].elements[0].distance.value/1000).toFixed(2))
							resolve()
						}
						else{
							reject()
						}
					}
				)
			})
		},
		setLogs({commit}, log){
			let date = new Date()
			commit('SET_LOGS', {
				text: log,
				timestamp: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`
			})
		}
	}
}