<template>
    <div class="page-container relative w-full">
        <div class="flex justify-between">
            <span>Has Ongoing Trip: {{ ongoingTrip.hasOngoingTrip }}</span>
        </div>
        <div class="flex flex-col text-xs w-full">
            <div v-for="(log, index) in logs" :key="index" class="inline-flex justify-between" :class="{'bg-white': index % 2 == 0}">
                <span :class="{'text-green-500': ['trip started', 'trip ended'].includes(log.text) }">{{ log.text }}</span>
                <span>{{ log.timestamp }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            logs: state => state.gpsMileTrackerModule.logs,
            ongoingTrip: state => state.gpsMileTrackerModule.ongoingTrip
        })
    }
}
</script>