<template>
    <div class="page-container">
        <router-link tag="div" v-for="(calculator, index) in calculators" :key="index" :to="`calculators/${calculator.path}`" class="flex flex-wrap content-center rounded-lg bg-white h-12 px-4 mb-3 border-2">
            <div class="container max-w-full flex justify-between">
				<span class="text-yellow-500 font-semibold">{{ calculator.name }}</span>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
				</svg>
			</div>
        </router-link>
    </div>
</template>
<script>
export default {
    data: () => ({
        calculators: [
            {
                name: 'Rental Calculator',
                path: 'rental'
            }
        ]
    })
}
</script>