import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBYAVxHVuj9uMNr4sr4Rwy8v4v38I6KndQ',
        libraries: 'places'
    }
})

export default {}