<template>
    <div class="min-h-screen">
        <Header ref="header" v-if="hasHeader" />
        <main>
            <router-view></router-view>
            <div class="fixed container max-w-full bottom-0 z-40" v-if="snackbar">
                <div class="flex mx-auto bottom-0 h-auto bg-custom-blue text-white rounded-md content-center py-4 pl-3 w-11/12 min-w-min mb-5 text-sm">
                    <div class="w-10/12">
                        {{ snackbarMsg }}
                    </div>
                    <div class="flex flex-grow" @click="snackbar = false">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import NoSleep from 'nosleep.js'
    
    export default {
        data: () => ({
            snackbarMsg: 'Hello World',
            snackbar: false,
            startTripAttempt: 0,
            hasStartedTrip: false,
            timeout: '',
            watchId: 0,
            timeoutDuration: 300000, //5mins
            executeEndTimeout: true
        }),
        computed: {
            hasHeader(){
                let withoutHeaders = ['login', 'Forgot Password', 'Reset Password']
                return !withoutHeaders.includes(this.$route.name)
            },
            ...mapState({
				ongoingTrip: state => state.gpsMileTrackerModule.ongoingTrip,
                origin: state => state.gpsMileTrackerModule.origin,
                currentLocation: state => state.gpsMileTrackerModule.currentLocation,
                distance: state => state.gpsMileTrackerModule.distance
			})
        },
        methods: {
            async getLocation(){
				new Promise((resolve, reject) => {
					this.watchId = navigator.geolocation.watchPosition(
						async position => {
                            this.$store.dispatch('setCurrentLocationCoordinates', position.coords)
                            await this.$store.dispatch('getAddress', {
                                coordinates: position.coords, 
                                point: 'currentLocation'
                            })
                            this.$store.dispatch('setLogs', `coords - ${position.coords.latitude},${position.coords.longitude}`)
                            this.$store.dispatch('setLogs', position.coords.speed !== null ? `speed - ${position.coords.speed.toFixed(2)} kph` : 'speed - 0 kph')
            
                            //auto start trip if speed is above 10kph
                            if(parseFloat(position.coords.speed) > 10 && !this.hasStartedTrip){
                                this.$store.dispatch('setLogs', `start attempt - ${this.startTripAttempt}`)
                                if(this.startTripAttempt == 3){
                                    this.startTripAttempt = 0 //reset start attempt
                                    this.hasStartedTrip = true //to immediately stop another attempt of starting a trip instead of waiting from vuex state
                                    this.$store.dispatch('setLogs', 'trip started')
                                    this.$store.dispatch('setLogs', `origin place id - ${this.currentLocation.placeId}`)
                                    this.$store.dispatch('setLogs', `origin coords - ${this.currentLocation.coordinates.lat}, ${this.currentLocation.coordinates.lng}`)
                                    this.$store.dispatch('setOriginCoordinates', position.coords)
                                    await this.$store.dispatch('getAddress', {
                                        coordinates: position.coords, 
                                        point: 'origin'
                                    })
                                    await this.$store.dispatch('startTrip', {
                                        origin_lat: position.coords.latitude,
                                        origin_lng: position.coords.longitude,
                                        origin_loc: this.origin.address,
                                        origin_place_id: this.origin.placeId,
                                        destination_lat: null,
                                        destination_lng: null,
                                        destination_loc: null,
                                        destination_place_id: null,
                                        distance: null
                                    })

                                    this.$store.commit('SET_SNACKBAR_MSG', {
                                        isActive: true,
                                        text: 'Movement more than 10kph detected. Started a trip'
                                    })
                                }
                                else{
                                    this.startTripAttempt++
                                }
                            }

                            
                            if(this.hasStartedTrip && !this.ongoingTrip.hasDestination){
                                //get ongoing trip
                                if(!this.ongoingTrip.hasOngoingTrip){
                                    await this.$store.dispatch('fetchOngoingTrip')
                                    this.$store.dispatch('fetchTrips', {
                                        filter: this.$route.name == 'Trips' && this.$route.params.filter ? this.$route.params.filter : 'All',
                                        page: 1,
                                        isLoadMore: false
                                    })
                                }

                                if(this.ongoingTrip.hasOngoingTrip){
                                    //clear timeout to refresh every update of coordinates if speed is above 3.21869kph/2mph
                                    if(parseFloat(position.coords.speed) > 3.21869 && position.coords.speed !== null){
                                        clearTimeout(this.timeout)
                                        this.executeEndTimeout = true
                                        this.$store.dispatch('setLogs', 'timeout cleared')
                                    }

                                    if(this.executeEndTimeout){
                                        this.executeEndTimeout = false
                                        this.$store.dispatch('setLogs', 'timeout executed')
                                        this.timeout = setTimeout(() => {
                                            this.$store.dispatch('setLogs', 'trip ended')
                                            this.$store.dispatch('setLogs', `destination place id - ${this.currentLocation.placeId}`)
                                            this.$store.dispatch('setLogs', `destination coords - ${this.currentLocation.coordinates.lat}, ${this.currentLocation.coordinates.lng}`)
                                            this.endTrip(position.coords)
                                            this.$store.commit('SET_SNACKBAR_MSG', {
                                                isActive: true,
                                                text: 'Slow speed for 5mins. Ended your trip'
                                            })
                                            clearTimeout(this.timeout)
                                            this.hasStartedTrip = false
                                            this.executeEndTimeout = true
                                        }, this.timeoutDuration)
                                    }
                                }
                                else{
                                    clearTimeout(this.timeout)
                                    this.hasStartedTrip = false
                                    this.$store.dispatch('setLogs', 'no ongoing trip - timeout cleared')
                                }
                            }

                            //auto end trip made from gps mile tracker page if has destination that is reached
							if(this.ongoingTrip.hasOngoingTrip && this.ongoingTrip.hasDestination && (position.coords.latitude == this.ongoingTrip.destinationCoordinates.lat && position.coords.longitude == this.ongoingTrip.destinationCoordinates.lng)){
								this.$store.dispatch('endTrip', {
                                    gpsMileTrackerId: this.ongoingTrip.ongoingGpsMileTrackerId
                                })
                                this.$store.commit('SET_SNACKBAR_MSG', {
                                    isActive: true,
                                    text: 'Ended your trip'
                                })
								navigator.geolocation.clearWatch(this.watchId)
                                if(this.$route.name == 'Trips'){
                                    this.$store.dispatch('fetchTrips', {
                                        filter: this.$route.params.filter ? this.$route.params.filter : 'All',
                                        page: 1,
                                        isLoadMore: false
                                    })
                                }
							}
				    		resolve()
						},
						error => {
							console.error(error.message)
							reject()
						},
						{
							enableHighAccuracy: true, 
							timeout: 5000, 
							maximumAge: 10000
						}
					)
				})
			},
            async endTrip(coordinates){
                await this.$store.dispatch('getAddress', {
                    coordinates: coordinates, 
                    point: 'currentLocation'
                })
                await this.$store.dispatch('getDistance', {
                    origin: this.origin.placeId, 
                    destination: this.currentLocation.placeId
                })
                await this.$store.dispatch('endTrip', {
                    gpsMileTrackerId: this.ongoingTrip.ongoingGpsMileTrackerId,
                    destination: {
                        destination_lat: coordinates.latitude,
                        destination_lng: coordinates.longitude,
                        destination_loc: this.currentLocation.address,
                        destination_place_id: this.currentLocation.placeId,
                        distance: this.distance
                    }
                })
                this.$store.dispatch('fetchTrips', {
                    filter: this.$route.name == 'Trips' && this.$route.params.filter ? this.$route.params.filter : 'All',
                    page: 1,
                    isLoadMore: false
                })
                this.$store.dispatch('fetchOngoingTrip')
            },
            async initLocation(){
                await this.$store.dispatch('fetchOngoingTrip')
                this.hasStartedTrip = this.ongoingTrip.hasOngoingTrip
                this.getLocation()
            }
        },
        created(){
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('bearerToken')}`
            this.$store.watch(state => state.snackbar, (state) => {
                this.snackbarMsg = state.text
                this.snackbar = true
                setTimeout(() => this.snackbar = false, 5000)
            })
        },
        async mounted(){
            if(localStorage.getItem('bearerToken') !== null){
                await this.initLocation()
            }

            let noSleep = new NoSleep()
            noSleep.enable()
        },
        beforeDestroy(){
			if("geolocation" in navigator){
				navigator.geolocation.clearWatch(this.watchId)
			}
		}
    }
</script>