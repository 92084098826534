import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Login from '../components/views/Login/Index'
import Dashboard from '../components/views/Dashboard/Index'

import Settings from '../components/views/Settings/Index'
import Property from '../components/views/Settings/Property'
import EditProperty from '../components/views/Settings/EditProperty'
import AddProperty from '../components/views/Settings/AddProperty'
import Activity from '../components/views/Settings/Activity'
import AddActivity from '../components/views/Settings/AddActivity'
import EditActivity from '../components/views/Settings/EditActivity'
import Notification from '../components/views/Settings/Notification'
import ChangePassword from '../components/views/Settings/ChangePassword'

import ReceiptManager from '../components/views/ReceiptManager/Index'

import GpsMileTracker from '../components/views/GpsMileTracker/Index'
import GpsMileTrackerSummary from '../components/views/GpsMileTracker/Summary'
import GpsMileTrackerLogs from '../components/views/GpsMileTracker/Logs'

import TimeLog from '../components/views/TimeLog/Index'
import TimeLogEditEvent from '../components/views/TimeLog/EditEvent'
import TimeLogAddEvent from '../components/views/TimeLog/AddEvent'

import Report from '../components/views/Report/Index'
import Offline from '../components/views/Offline'
import ForgotPassword from '../components/views/ForgotPassword/Email'
import ResetPassword from '../components/views/ForgotPassword/Reset'

import Calculator from '../components/views/Calculator/Index'
import Rental from '../components/views/Calculator/Rental'

const routes = [
	{
		component: Login,
		name: 'login',
		path: '/login'
	},
	{
		component: Dashboard,
		name: 'Dashboard',
		path: '/dashboard',
		meta: {
			auth: true,
			showBack: false
		}
	},
	{
		component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/time-log',
		children: [
			{
				component: TimeLog,
				name: 'Time Log',
				path: '',
				meta: {
					auth: true,
				}
			},
			{
				component: TimeLogAddEvent,
				name: 'Add Event',
				path: 'add',
				meta: {
					auth: true,
					showBack: true
				}
			},
			{
				component: TimeLogEditEvent,
				name: 'Edit Event',
				path: 'edit/:id',
				meta: {
					auth: true,
					showBack: true
				}
			},
		]
	},
	{
		component: ReceiptManager,
		name: 'Receipt Manager',
		path: '/receipt-manager/:id?',
		meta: {
			auth: true
			//showBack for this route is conditionally set on beforeEach below
		}
	},
	{
		component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/gps-mile-tracker',
		children: [
			{
				component: GpsMileTrackerLogs,
				name: 'Logs',
				path: 'logs',
				meta: {
					auth: true,
					showBack: true
				}
			},
			{
				component: GpsMileTracker,
				name: 'Trips',
				path: ':filter?',
				meta: {
					auth: true
				}
			},
			{
				component: GpsMileTrackerSummary,
				name: 'Summary',
				path: 'summary/:id',
				meta: {
					auth: true,
					showBack: true
				}
			},
			
		],
		meta: {
			auth: true
		}
	},
	{
		component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/settings',
		children: [
			{
				component: Settings,
				name: 'Settings',
				path: '',
				meta: {
					auth: true
				}
			},
			{
				component: {
					render (x) {
						return x('router-view')
					}
				},
				path: 'properties',
				children: [
					{
						component: Property,
						name: 'Properties',
						path: '',
						meta: {
							auth: true,
							showBack: true
						}
					},
					{
						component: AddProperty,
						name: 'Add Property',
						path: 'add',
						meta: {
							auth: true,
							showBack: true,
							backTo: '/settings/properties'
						}
					},
					{
						component: EditProperty,
						name: 'Edit Property',
						path: ':id',
						meta: {
							auth: true,
							showBack: true,
							backTo: '/settings/properties'
						}
					},
				],
				meta: {
					auth: true,
					showBack: true,
					backTo: '/settings'
				}
			},
			{
				component: {
					render (x) {
						return x('router-view')
					}
				},
				path: 'activities',
				children: [
					{
						component: Activity,
						name: 'Activities',
						path: '',
						meta: {
							auth: true,
							showBack: true
						}
					},
					{
						component: AddActivity,
						name: 'Add Activity',
						path: 'add',
						meta: {
							auth: true,
							showBack: true,
							backTo: '/settings/activities'
						}
					},
					{
						component: EditActivity,
						name: 'Edit Activity',
						path: ':id',
						meta: {
							auth: true,
							showBack: true,
							backTo: '/settings/activities'
						}
					},
				],
				meta: {
					auth: true,
					showBack: true,
					backTo: '/settings'
				}
			},
			{
				component: {
					render (x) {
						return x('router-view')
					}
				},
				path: '',
				children: [
					{
						component: Notification,
						name: 'Notifications',
						path: 'notifications',
						meta: {
							auth: true,
							showBack: true
						}
					}
				]
			},
			{
				component: {
					render (x) {
						return x('router-view')
					}
				},
				component: ChangePassword,
				name: 'Change Password',
				path: 'change-password',
				meta: {
					auth: true,
					showBack: true
				}
			},
		],
		meta: {
			auth: true
		}
	},
	{
		component: Report,
		name: 'Reports',
		path: '/reports',
		meta: {
			auth: true
		}
	},
	{
		component: Offline,
		name: 'offline',
		path: '/offline',
		meta: {
			auth: true
		}
	},
	{
		component: ForgotPassword,
		name: 'Forgot Password',
		path: '/forgot-password'
	},
	{
		component: ResetPassword,
		name: 'Reset Password',
		path: '/reset-password/:token'
	},
	{
		component: {
			render (x) {
				return x('router-view')
			}
		},
		path: '/calculators',
		children: [
			{
				component: Calculator,
				name: 'Calculators',
				path: '/',
				meta: {
					auth: true
				}
			},
			{
				component: Rental,
				name: 'Rental Calculator',
				path: 'rental',
				meta: {
					auth: true,
					showBack: true
				}
			}
		]
	}
]

let router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	let hasToken = localStorage.getItem('bearerToken') != null && localStorage.getItem('bearerToken') != 'undefined'
	//check if route requires authentication
	if(to.matched.some(record => record.meta.auth)){
		if(!hasToken){
			next({
				path: '/login'
			})
		}
		else {
			//receipt manager route check
			if(to.name == 'Receipt Manager'){
				to.meta.showBack = (to.params.id === undefined) ? false : true
			}
			next()
		}
	}
	else{
		if(to.fullPath == '/'){
			if(hasToken){
				next({
					path: '/dashboard'
				})
			}
			else{
				next({
					path: '/login'
				})
			}
		}
		else{
			next()
		}
	}
})

export default router