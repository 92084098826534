<template>
	<div class="page-container">
		<!-- tabs -->
		<div class="h-10 w-full grid grid-cols-4 mb-3">
			<div class="flex-1 text-center" :class="[ $route.params.filter == tab.name || (tab.name == 'all' && $route.params.filter == undefined ) ? 'border-b-2 border-b-blue font-semibold text-custom-blue' : 'text-gray-500' ]" v-for="(tab, index) in tabs" :key="`tab-${index}`" @click="changeTab(tab.name)">
				<span>{{ tab.name | upperCaseFirst }}</span>
			</div>
		</div>
		<!-- trips -->
		<router-link tag="div" v-for="(trip, index) in trips" :key="index" class="border-2 bg-white rounded-md p-2 mb-3" :to="`/gps-mile-tracker/summary/${trip.id}`">
			<div class="flex justify-between">
				<div class="my-auto inline-flex text-yellow-500">
					<img :src="'../storage/img/destination.svg'" class="h4 w-4 mr-1" alt="">
					<span v-if="trip.distance">{{ trip.distance }}</span><span v-else>--</span> km
				</div>
				<div class="my-auto inline-flex text-yellow-500" v-if="trip.status == 'ENDED'">
					<svg xmlns="http://www.w3.org/2000/svg" class="text-custom-blue h-4 w-4 my-auto mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
					{{ trip.total_hours }}
				</div>
				<div v-if="trip.status == 'ENDED'" class="bg-green-100 rounded-md p-2 w-24 text-center text-green-600 my-auto">
					Ended
				</div>
				<div v-else-if="trip.status == 'ONGOING'" class="bg-blue-100 rounded-md p-2 w-24 text-center text-blue-600 my-auto">
					Ongoing
				</div>
				<div v-else class="bg-red-100 rounded-md p-2 w-24 text-center text-red-600 my-auto">
					Cancelled
				</div>
			</div>
			<hr class="my-2">
			<div class="flex flex-col mt-5 text-sm w-full">
				<div class="inline-flex">
					<img :src="'../storage/img/origin.svg'" class="h4 w-4 mr-3" alt="">
					<div class="flex flex-col flex-grow truncate">
						<span class="text-custom-blue font-semibold">
							{{ trip.start_time }}
						</span>
						<span class="text-gray-400 truncate">
							{{ trip.origin_loc }}
						</span>
					</div>
				</div>
				<div class="inline-flex mt-3">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-3 text-custom-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
					</svg>
					<div class="flex flex-col flex-grow truncate">
						<span class="text-custom-blue font-semibold mr-3">
							{{ trip.end_time }}
						</span>
						<span class="text-gray-400 truncate">
							{{ trip.destination_loc }}
						</span>
					</div>
				</div>
			</div>
		</router-link>
		<!-- floating actions buttons -->
		<button class="btn-fab" @click="dialog = true" v-if="!ongoingTrip.hasOngoingTrip">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
		</button>
		<button class="btn-fab" v-else @click="showConfirmationDialog = true">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
			</svg>
		</button>
		<!-- dialog -->
		<div class="flex dialog-background" v-if="dialog">
			<div class="flex flex-col space-y-5 bg-white text-gray-500 m-auto rounded h-auto w-11/12 md:w-60 px-3 py-5">
				<div class="flex flex-col border-2 rounded p-3 bg-gray-50">
					<span class="text-gray-400 text-xs">Origin</span>
					<span class="text-yellow-500 text-sm">{{ currentLocation.address }}</span>
				</div>
				<div class="relative">
					<div class="custom-text-area">
						<textarea name="destination" type="text" placeholder=" " v-model="destination.address" autocomplete="nope"></textarea>
						<label for="destination">Destination</label>
					</div>
					<div class="absolute z-50 bg-white w-full border-2 rounded h-auto flex flex-col px-2 space-y-2" v-if="placeSuggestions.length != 0">
						<span @click="setSuggestion(suggestion.description)" v-for="(suggestion, index) in placeSuggestions" :key="`suggest-${index}`" class="truncate">{{ suggestion.description }}</span>
					</div>
				</div>
				<div>
					<GmapMap
						:center="mapCenter"
						:zoom="15"
						map-type-id="roadmap"
						style="width: auto; height: 300px; margin: 0px"
						:options="{
							streetViewControl: false,
							fullscreenControl: false,
							mapTypeControl: false,
							zoomControl: true,	
							disableDefaultUi: false,
							gestureHandling: 'greedy'
						}"
						ref="mapsRef"
						class="mt-2">
							<Direction 
								:destination="destination.coordinates"
								:origin="currentLocation.coordinates"
								travelMode="DRIVING"
								v-if="hasDestination"
							/>
							<GmapMarker
								:position="currentLocation.coordinates"/>
					</GmapMap>
				</div>
				<div class="flex justify-end">
					<button @click="dialog = false">
						Cancel
					</button>
					<button class="btn ml-5" :class="[ canStartTrip ? 'text-white bg-custom-blue' : 'text-gray-500 bg-gray-100']" @click="startTrip" :disabled="!canStartTrip">
						Start Trip
					</button>
				</div>
			</div>
		</div>
		<ConfirmationDialog v-if="showConfirmationDialog">
			<template v-slot:message>
				You want to end your ongoing trip?
			</template>
			<template v-slot:action>
				<button @click="endTrip">OK</button>
				<button @click="showConfirmationDialog = false">Cancel</button>
			</template>
		</ConfirmationDialog>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import Direction from '../../../plugins/directions.js'
	export default{
		components: {
			Direction
		},
		data: () => ({
			isLoading: false,
			dialog: false,
			mapCenter: {
				lat: '',
				lng: ''
			},
			destination: {
				coordinates: {
					lat: 0,
					lng: 0
				},
				address: '',
				placeId: ''
			},
			showConfirmationDialog: false,
			placeSuggestions: [],
			suggestions: null,
			tabs: [
				{
					name: 'all'
				},
				{
					name: 'ongoing'
				},
				{
					name: 'ended'
				},
				{
					name: 'cancelled'
				},
			],
			canStartTrip: false,
			hasSelectedSuggestion: false
		}),
		computed: {
			...mapState({
				trips: state => state.gpsMileTrackerModule.trips,
				ongoingTrip: state => state.gpsMileTrackerModule.ongoingTrip,
				meta: state => state.gpsMileTrackerModule.meta,
				origin: state => state.gpsMileTrackerModule.origin,
				currentLocation: state => state.gpsMileTrackerModule.currentLocation,
				distance: state => state.gpsMileTrackerModule.distance
			}),
			hasDestination(){
				return this.destination.address != '' && typeof this.destination.address !== 'undefined'
			},
			isLastPage(){
				return this.meta.currentPage == this.meta.lastPage
			}
		},
		watch: {
			'destination.address'(current, prev){
				//made this watcher since keypress doesn't work with android devices
				if(!this.hasSelectedSuggestion){
					this.getSuggestions()
				}
				//created this variable to prevent suggesting when a user selected a suggestion since watch will be triggered by that model change
				this.hasSelectedSuggestion = false
			}
		},
		filters: {
			upperCaseFirst(string){
				return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
			}
		},
		methods: {
			async getTrips(isLoadMore){
				this.isLoading = true
				await this.$store.dispatch('fetchTrips', {
					filter: this.$route.params.filter ? this.$route.params.filter : 'All',
					page: isLoadMore ? this.meta.currentPage+1 : 1,
					isLoadMore: isLoadMore
				})
				await this.$store.dispatch('fetchOngoingTrip')
				this.isLoading = false
			},
			async startTrip(){
				this.dialog = false
				this.isLoading = true
				await this.$store.dispatch('getDistance', {
					origin: this.currentLocation.placeId, 
					destination: this.destination.placeId
				})
				await this.$store.dispatch('startTrip', {
					origin_lat: this.currentLocation.coordinates.lat,
					origin_lng: this.currentLocation.coordinates.lng,
					origin_loc: this.currentLocation.address,
					origin_place_id: this.currentLocation.placeId,
					destination_lat: this.destination.coordinates.lat,
					destination_lng: this.destination.coordinates.lng,
					destination_loc: this.destination.address,
					destination_place_id: this.destination.placeId,
					distance: this.distance
				})

				await this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Started your trip'
				})

				this.getTrips(false)
				this.isLoading = false
			},
			async endTrip(){
				this.showConfirmationDialog = false
				this.isLoading = true
				this.end = false
				let payload = {
						gpsMileTrackerId: this.ongoingTrip.ongoingGpsMileTrackerId
					}
				if(!this.ongoingTrip.hasDestination){
					await this.$store.dispatch('getDistance', {
						origin: this.currentLocation.placeId, 
						destination: this.destination.placeId
					})
					
					payload.destination = {
						destination_lat: this.currentLocation.coordinates.lat,
                        destination_lng: this.currentLocation.coordinates.lng,
                        destination_loc: this.currentLocation.address,
                        destination_place_id: this.currentLocation.placeId
					}
					payload.destination.distance = this.distance
				}
				await this.$store.dispatch('endTrip', payload)
				
				await this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Ended your trip'
				})

				this.getTrips(false)
				this.isLoading = false
			},
			async getCoordinatesFromAddress(){
				if(this.hasDestination){
					var instance = axios.create({})

					delete instance.defaults.headers.common['X-CSRF-TOKEN']
					delete instance.defaults.headers.common['X-Requested-With']
					delete instance.defaults.headers.common['Authorization']

					await instance.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.destination.address}&key=AIzaSyBYAVxHVuj9uMNr4sr4Rwy8v4v38I6KndQ`, {
						headers: {
							'Accept-Language': 'en;q=0.8'
						}
					}).then(response => {
						this.destination.coordinates = this.mapCenter = response.data.results[0].geometry.location
						this.destination.placeId = response.data.results[0].place_id
					})
				}
				else{
					this.mapCenter = this.origin.coordinates
				}
			},
			async loadMore(){
				this.getTrips(true)
			},
			async changeTab(filter){
				this.$router.push(`/gps-mile-tracker/${filter}`)
				this.getTrips(false)
			},
			getSuggestions(){
				if(this.destination.address && this.destination.address.length > 3){
					return new Promise((resolve, reject) => {
						var service = new google.maps.places.AutocompleteService()
						service.getPlacePredictions({
								input: this.destination.address,
								types: []
							},
							(response, status) => {
								if(status === "OK"){
									this.placeSuggestions = response
									resolve()
								}
								else{
									reject()
								}
							}
						)
					})
				}
				else{
					this.placeSuggestions = []
					this.canStartTrip = false
				}
			},
			setSuggestion(suggestion){
				this.hasSelectedSuggestion = true
				this.canStartTrip = true
				this.destination.address = suggestion
				this.getCoordinatesFromAddress()
				this.placeSuggestions = []
			}
		},
		async mounted(){
			await this.getTrips(false)
			this.mapCenter = this.currentLocation.coordinates
		}
	}
</script>