<template>
	<div class="page-container">
		<div class="custom-text-area-tall">
			<textarea name="activity" id="activity" type="text" placeholder=" " v-model="activity" autocomplete="nope"></textarea>
			<label for="activity">Activity</label>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="save">Add</button>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	export default{
		data: () => ({
			activity: '',
			isLoading: false
		}),
		methods: {
			async save(){
				this.isLoading = true
				await this.$store.dispatch('addActivity', this.activity)
				this.$store.commit('SET_SNACKBAR_MSG', 'Added activity')
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Added activity'
				})
				this.isLoading = false
				this.$router.replace('/settings/activities')
			}
		}
	}
</script> 