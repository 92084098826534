import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

import propertyModule from './property'
import activityModule from './activity'
import locationModule from './location'
import receiptManagerModule from './receiptmanager'
import timeLogModule from './timelog'
import gpsMileTrackerModule from './gpsmiletracker'
import reportsModule from './reports'
import notificationModule from './notification'
import accountModule from './account'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		propertyModule,
		activityModule,
		locationModule,
		receiptManagerModule,
		timeLogModule,
		gpsMileTrackerModule,
		reportsModule,
		notificationModule,
		accountModule
	},
	state: {
		endpoint: '/api/v1',
		snackbar: {
			isActive: false,
			text: ''
		}
	},
	mutations: {
		SET_SNACKBAR_MSG(state, payload){
			state.snackbar = payload
		}
	},
	actions: {
		redirectToLogin(){
			localStorage.removeItem('bearerToken');
			localStorage.removeItem('userFullName');
			router.push('/login');
		}
	}
})