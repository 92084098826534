<template>
	<div class="page-container">
		<div class="custom-select">
			<select name="property" id="property" type="text" v-model="timeLog.property_id">
				<option :value="property.property_id" v-for="(property, index) in properties" :key="`property-${index}`">{{ property.property }}</option>
			</select>
			<label for="property">Property</label>
		</div>
		<div class="custom-select">
			<select name="activity" id="activity" type="text" v-model="timeLog.activity_id">
				<option :value="activity.id" v-for="(activity, index) in activities" :key="`activity-${index}`">{{ activity.activity }}</option>
			</select>
			<label for="activity">Activity</label>
		</div>
		<div class="custom-text-field">
			<input name="note" id="note" type="text" placeholder=" " v-model="timeLog.note" autocomplete="nope" />
			<label for="note">Note</label>
		</div>
		<div class="mb-2">
			<VueCtkDateTimePicker
				v-model="timeLog.date"
				format="YYYY-MM-DD"
				formatted="ll"
				range
				only-date
				no-shortcuts
				color="#385496"
				no-label/>
		</div>
		<div class="flex space-x-1">
			<div class="custom-select flex-1">
				<select name="start-time" id="start-time" type="text" v-model="timeLog.start_hour" @change="computeTimeDiff">
					<option :value="start.value" v-for="(start, index) in startTime" :key="`start-time-${index}`">{{ start.text }}</option>
				</select>
				<label for="start-time">Start</label>
			</div>
			<div class="custom-select flex-1">
				<select name="end-time" id="end-time" type="text" v-model="timeLog.end_hour" @change="computeTimeDiff">
					<option :value="end.value" v-for="(end, index) in endTime" :key="`end-time-${index}`">{{ end.text }}</option>
				</select>
				<label for="end-time">End</label>
			</div>
			<div class="flex items-center justify-center w-full flex-1">
				<label for="toggleB" class="flex items-center cursor-pointer">
					<div class="relative">
						<input type="checkbox" id="toggleB" class="sr-only" v-model="timeLog.allDay">
						<div class="block bg-white w-10 h-6 rounded-full border-2"></div>
						<div class="dot absolute left-1 top-1 bg-gray-500 w-4 h-4 rounded-full transition"></div>
					</div>
					<div class="ml-3 text-gray-400">
						All Day
					</div>
				</label>
			</div>
		</div>
		<div class="custom-text-field">
			<input name="total_hours" id="total_hours" type="text" placeholder=" " v-model="timeLog.total_hours" autocomplete="nope" />
			<label for="total_hours">Total Hours</label>
		</div>
		<div class="border-2 text-center rounded bg-white py-3 text-yellow-500 mb-2">
			<input @change="setAttachments" name="attachment" id="attachment" type="file" hidden multiple accept="image/*" />
			<label for="attachment" class="w-full flex justify-center h-full">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
				</svg>
				Attachments
			</label>
			<span class="text-xs">
				{{ timeLog.files.length }}
				<template v-if="timeLog.files.length <= 1">file</template>
				<template v-else>files</template>
			</span>
		</div>
		<div class="flex">
			<input name="estate" id="estate" type="checkbox" placeholder=" " v-model="timeLog.is_real_estate_related_activity" class="h-5 w-5 my-auto" />
			<label for="estate" class="ml-2 my-auto text-yellow-500">Real Estate Related Activity?</label>
		</div>
		<div class="flex flex-row">
			<div v-for="(attachment, index) in timeLog.attachments" :key="`img${index}`" @click="showPreview(attachment.file_url, attachment.id, attachment.original_file_name)" class="m-1">
				<img :src="attachment.file_url" alt="" loading="lazy" width="150px" height="100px">
				<span class="text-yellow-500 text-xs font-light text-center">{{ attachment.original_file_name }}</span>
			</div>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="updateTimelog">Update</button>
			<div class="flex space-x-1">
				<button class="btn btn-secondary mb-3" @click="startTimer" v-if="!timerStatus.hasOngoingTimer">Start</button>
				<button class="btn btn-secondary mb-3" @click="stopTimer" v-else>Stop</button>
				<button class="btn btn-secondary mb-3" @click="showLogs = true">View Logs</button>
			</div>
			<button class="btn btn-secondary mb-3" @click="showConfirmationDialog = true">Delete</button>
		</div>
		<div class="flex dialog-background" v-if="showLogs">
			<button class="absolute top-0 right-0 mr-3 mt-3 border-white border-2 rounded" @click="showLogs = false">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</button>
			<div class="bg-white text-gray-500 m-auto rounded h-auto w-11/12 md:w-60 py-5 px-2 flex flex-col">
				<table class="table-auto" v-if="timeLog.timer.length != 0">
					<thead>
						<tr>
							<th>Started</th>
							<th>Stopped</th>
							<th>Time(secs)</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(timer, index) in timeLog.timer" :key="`log-${index}`">
							<td>{{ timer.start_time }}</td>
							<td>{{ timer.end_time }}</td>
							<td class="text-center">{{ timer.total_time }}</td>
						</tr>
						<tr>
							<td></td>
							<td class="text-center font-bold">Total(Hours)</td>
							<td class="text-center font-bold">{{ totalHours }}</td>
						</tr>

					</tbody>
					
				</table>
				<div v-else>
					<span class="text-center">No Logs</span>
				</div>
			</div>
		</div>
		<Loading v-if="isLoading" />
		<ConfirmationDialog v-if="showConfirmationDialog">
			<template v-slot:message>
				Are you sure you want to delete?
			</template>
			<template v-slot:action>
				<button @click="deleteTimeLog">OK</button>
				<button @click="showConfirmationDialog = false">Cancel</button>
			</template>
		</ConfirmationDialog>
		<ImagePreview :image="image" :movebtn="false" v-if="isShowPreview" @close="isShowPreview = false" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import dayjs from 'dayjs'

	export default{
		data: () => ({
			isLoading: false,
			isPropertyLoading: false,
			isActivityLoading: false,
			modal: false,
			timeLog: {
				files: []
			},
			isDisabled: false,
			isShowPreview: false,
			image: {
				url: '',
				id: '',
				name: ''
			},
			startTime: [
				{value: '--', text: '--'},
				{value: '00:00:00', text: '12:00 am'},
				{value: '00:30:00', text: '12:30 am'},
				{value: '01:00:00', text: '1:00 am'},
				{value: '01:30:00', text: '1:30 am'},
				{value: '02:00:00', text: '2:00 am'},
				{value: '02:30:00', text: '2:30 am'},
				{value: '03:00:00', text: '3:00 am'},
				{value: '03:30:00', text: '3:30 am'},
				{value: '04:00:00', text: '4:00 am'},
				{value: '04:30:00', text: '4:30 am'},
				{value: '05:00:00', text: '5:00 am'},
				{value: '05:30:00', text: '5:30 am'},
				{value: '06:00:00', text: '6:00 am'},
				{value: '06:30:00', text: '6:30 am'},
				{value: '07:00:00', text: '7:00 am'},
				{value: '07:30:00', text: '7:30 am'},
				{value: '08:00:00', text: '8:00 am'},
				{value: '08:30:00', text: '8:30 am'},
				{value: '09:00:00', text: '9:00 am'},
				{value: '09:30:00', text: '9:30 am'},
				{value: '10:00:00', text: '10:00 am'},
				{value: '10:30:00', text: '10:30 am'},
				{value: '11:00:00', text: '11:00 am'},
				{value: '11:30:00', text: '11:30 am'},
				{value: '12:00:00', text: '12:00 pm'},
				{value: '12:30:00', text: '12:30 pm'},
				{value: '13:00:00', text: '1:00 pm'},
				{value: '13:30:00', text: '1:30 pm'},
				{value: '14:00:00', text: '2:00 pm'},
				{value: '14:30:00', text: '2:30 pm'},
				{value: '15:00:00', text: '3:00 pm'},
				{value: '15:30:00', text: '3:30 pm'},
				{value: '16:00:00', text: '4:00 pm'},
				{value: '16:30:00', text: '4:30 pm'},
				{value: '17:00:00', text: '5:00 pm'},
				{value: '17:30:00', text: '5:30 pm'},
				{value: '18:00:00', text: '6:00 pm'},
				{value: '18:30:00', text: '6:30 pm'},
				{value: '19:00:00', text: '7:00 pm'},
				{value: '19:30:00', text: '7:30 pm'},
				{value: '20:00:00', text: '8:00 pm'},
				{value: '20:30:00', text: '8:30 pm'},
				{value: '21:00:00', text: '9:00 pm'},
				{value: '21:30:00', text: '9:30 pm'},
				{value: '22:00:00', text: '10:00 pm'},
				{value: '22:30:00', text: '10:30 pm'},
				{value: '23:00:00', text: '11:00 pm'},
				{value: '23:30:00', text: '11:30 pm'},
			],
			endTime: [
				{value: '--', text: '--'},
				{value: '00:30:00', text: '12:30 am'},
				{value: '01:00:00', text: '1:00 am'},
				{value: '01:30:00', text: '1:30 am'},
				{value: '02:00:00', text: '2:00 am'},
				{value: '02:30:00', text: '2:30 am'},
				{value: '03:00:00', text: '3:00 am'},
				{value: '03:30:00', text: '3:30 am'},
				{value: '04:00:00', text: '4:00 am'},
				{value: '04:30:00', text: '4:30 am'},
				{value: '05:00:00', text: '5:00 am'},
				{value: '05:30:00', text: '5:30 am'},
				{value: '06:00:00', text: '6:00 am'},
				{value: '06:30:00', text: '6:30 am'},
				{value: '07:00:00', text: '7:00 am'},
				{value: '07:30:00', text: '7:30 am'},
				{value: '08:00:00', text: '8:00 am'},
				{value: '08:30:00', text: '8:30 am'},
				{value: '09:00:00', text: '9:00 am'},
				{value: '09:30:00', text: '9:30 am'},
				{value: '10:00:00', text: '10:00 am'},
				{value: '10:30:00', text: '10:30 am'},
				{value: '11:00:00', text: '11:00 am'},
				{value: '11:30:00', text: '11:30 am'},
				{value: '12:00:00', text: '12:00 pm'},
				{value: '12:30:00', text: '12:30 pm'},
				{value: '13:00:00', text: '1:00 pm'},
				{value: '13:30:00', text: '1:30 pm'},
				{value: '14:00:00', text: '2:00 pm'},
				{value: '14:30:00', text: '2:30 pm'},
				{value: '15:00:00', text: '3:00 pm'},
				{value: '15:30:00', text: '3:30 pm'},
				{value: '16:00:00', text: '4:00 pm'},
				{value: '16:30:00', text: '4:30 pm'},
				{value: '17:00:00', text: '5:00 pm'},
				{value: '17:30:00', text: '5:30 pm'},
				{value: '18:00:00', text: '6:00 pm'},
				{value: '18:30:00', text: '6:30 pm'},
				{value: '19:00:00', text: '7:00 pm'},
				{value: '19:30:00', text: '7:30 pm'},
				{value: '20:00:00', text: '8:00 pm'},
				{value: '20:30:00', text: '8:30 pm'},
				{value: '21:00:00', text: '9:00 pm'},
				{value: '21:30:00', text: '9:30 pm'},
				{value: '22:00:00', text: '10:00 pm'},
				{value: '22:30:00', text: '10:30 pm'},
				{value: '23:00:00', text: '11:00 pm'},
				{value: '23:30:00', text: '11:30 pm'},
			],
			showConfirmationDialog: false,
			showLogs: false
		}),
		computed: {
			...mapState({
				properties: state => state.propertyModule.properties,
				property: state => state.propertyModule.property,
				activities: state => state.activityModule.activities,
				timerStatus: state => state.timeLogModule.timerStatus
			}),
			totalHours(){
				let sum = 0
				//fetch items with end_time
				let validTimer = this.timeLog.timer.filter(timer => timer.hasOwnProperty('end_time'))
				validTimer.forEach(time => sum+=time.total_time)
				let totalHours = (sum/60)/60
				return totalHours.toFixed(2)
			}
		},
		methods: {
			async getProperties(){
				this.isPropertyLoading = true
				await this.$store.dispatch('fetchProperties')
				this.isPropertyLoading = false
			},
			async getProperty(propertyId){
				await this.$store.dispatch('fetchProperty', propertyId)
			},
			async getActivities(){
				this.isActivityLoading = true
				await this.$store.dispatch('fetchActivities')
				this.isActivityLoading = false
			},
			async getTimeLog(){
				await this.$store.dispatch('getTimeLog', this.$route.params.id)
				this.timeLog = this.$store.getters.timeLog
			},
			async getContents(){
				await this.getProperties()
				await this.getActivities()
				await this.getTimeLog()
				this.isDisabled = this.timeLog.allDay
				this.isLoading = false
			},
			allDayEvent(evt){
				if(evt === true){
					this.isDisabled = true
					this.timeLog.start_hour = '--'
					this.timeLog.end_hour = '--'
					let noOfDays = this.timeLog.date.end == null ? 1 : Math.ceil(Math.abs(new Date(dayjs(this.timeLog.date.end).format('YYYY/MM/DD ')+'23:59:59') - new Date(dayjs(this.timeLog.date.start).format('YYYY/MM/DD ')+'00:00:00')) / (1000 * 60 * 60 * 24))
					this.timeLog.total_hours = 24 * noOfDays
				}
				else{
					this.timeLog.total_hours = 0
					this.isDisabled = false
				}
			},
			async updateTimelog(){
				this.isLoading = true
				if(this.timeLog.date.end != null){
					this.timeLog.start_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 00:00:00` : `${this.timeLog.date.start} ${this.timeLog.start_hour}`
					this.timeLog.end_time = this.timeLog.allDay == true ? `${this.timeLog.date.end} 23:23:59` : `${this.timeLog.date.end} ${this.timeLog.end_hour}`
				}
				else{
					this.timeLog.start_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 00:00:00` : `${this.timeLog.date.start} ${this.timeLog.start_hour}`
					this.timeLog.end_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 23:23:59` : `${this.timeLog.date.start} ${this.timeLog.end_hour}`
				}
				await this.$store.dispatch('updateTimeLog', this.timeLog)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Updated time log event'
				})
				this.isLoading = false
				this.$router.push('/time-log')
			},
			showConfirmation(){
				this.showConfirmationDialog = true
			},
			async deleteTimeLog(){
				this.isLoading = true
				await this.$store.dispatch('deleteTimeLog', this.$route.params.id)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Deleted time log event'
				})
				this.isLoading = false
				this.$router.push('/time-log')
			},
			showPreview(imageUrl, imageId, imageName){
				this.image.url = imageUrl
				this.image.id = imageId
				this.image.name = imageName
				this.isShowPreview = true
			},
			async startTimer(){
				this.$store.dispatch('startTimer', this.$route.params.id)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Timer started'
				})
			},
			async stopTimer(){
				this.$store.dispatch('stopTimer', {
					timerId: this.timerStatus.ongoingTimerId,
					timeLogId: this.$route.params.id
				})
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Timer stopped'
				})
			},
			computeTimeDiff(){
				if(this.timeLog.start_hour != '--' && this.timeLog.end_hour != '--'){
					let startDate = dayjs(`${this.timeLog.date.start} ${this.timeLog.start_hour}`).format('YYYY/MM/DD HH:mm:ss')
					let endDate = this.timeLog.date.end == null ? `${this.timeLog.date.start} ${this.timeLog.end_hour}` : `${this.timeLog.date.end} ${this.timeLog.end_hour}`
					let formattedEndDate = dayjs(endDate).format('YYYY/MM/DD HH:mm:ss')
					let milliseconds = Math.abs(new Date(formattedEndDate) - new Date(startDate))
					this.timeLog.total_hours = milliseconds/36e5
				}
			},
			setAttachments(file){
				this.timeLog.files = file.target.files
			}		
		},
		async mounted(){
			this.isLoading = true
			await this.getContents()
			this.isLoading = false
		}
	}
</script>