<template>
	<div class="page-container">
		<div class="custom-select">
			<select name="property" id="property" type="text" v-model="timeLog.property_id">
				<option :value="property.property_id" v-for="(property, index) in properties" :key="`property-${index}`">{{ property.property }}</option>
			</select>
			<label for="property">Property</label>
		</div>
		<div class="custom-select">
			<select name="activity" id="activity" type="text" v-model="timeLog.activity_id">
				<option :value="activity.id" v-for="(activity, index) in activities" :key="`activity-${index}`">{{ activity.activity }}</option>
			</select>
			<label for="activity">Activity</label>
		</div>
		<div class="custom-text-field">
			<input name="note" id="note" type="text" placeholder=" " v-model="timeLog.note" autocomplete="nope" />
			<label for="note">Note</label>
		</div>
		<div class="mb-2">
			<VueCtkDateTimePicker
				v-model="timeLog.date"
				format="YYYY-MM-DD"
				formatted="ll"
				range
				only-date
				no-shortcuts
				color="#385496"
				no-label/>
		</div>
		<div class="flex space-x-1">
			<div class="custom-select flex-1">
				<select name="start-time" id="start-time" type="text" v-model="timeLog.start_hour" @change="computeTimeDiff">
					<option :value="start.value" v-for="(start, index) in startTime" :key="`start-time-${index}`">{{ start.text }}</option>
				</select>
				<label for="start-time">Start</label>
			</div>
			<div class="custom-select flex-1">
				<select name="end-time" id="end-time" type="text" v-model="timeLog.end_hour" @change="computeTimeDiff">
					<option :value="end.value" v-for="(end, index) in endTime" :key="`end-time-${index}`">{{ end.text }}</option>
				</select>
				<label for="end-time">End</label>
			</div>
			<div class="flex items-center justify-center w-full flex-1">
				<label for="toggleB" class="flex items-center cursor-pointer">
					<div class="relative">
						<input type="checkbox" id="toggleB" class="sr-only" v-model="timeLog.allDay">
						<div class="block bg-white w-10 h-6 rounded-full border-2"></div>
						<div class="dot absolute left-1 top-1 bg-gray-500 w-4 h-4 rounded-full transition"></div>
					</div>
					<div class="ml-3 text-gray-400">
						All Day
					</div>
				</label>
			</div>
		</div>
		<div class="custom-text-field">
			<input name="total_hours" id="total_hours" type="text" placeholder=" " v-model="timeLog.total_hours" autocomplete="nope" />
			<label for="total_hours">Total Hours</label>
		</div>
		<div class="border-2 text-center rounded bg-white py-3 text-yellow-500 mb-2">
			<input @change="setAttachments" name="attachment" id="attachment" type="file" hidden multiple accept="image/*" />
			<label for="attachment" class="w-full flex justify-center h-full">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
				</svg>
				Attachments
			</label>
			<span class="text-xs">
				{{ timeLog.files.length }}
				<template v-if="timeLog.files.length <= 1">file</template>
				<template v-else>files</template>
			</span>
		</div>
		<div class="flex">
			<input name="estate" id="estate" type="checkbox" placeholder=" " v-model="timeLog.is_real_estate_related_activity" class="h-5 w-5 my-auto" />
			<label for="estate" class="ml-2 my-auto text-yellow-500">Real Estate Related Activity?</label>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="addTimeLog">Add</button>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import dayjs from 'dayjs'

	export default{
		data: () => ({
			isLoading: false,
			timeLog:{
				property_id: '',
				activity_id: '',
				property_address: '',
				note: '',
				date: {
					start: dayjs(new Date()).format('YYYY-MM-DD')
				},
				start_time: '',
				end_time: '',
				total_hours: 0,
				is_real_estate_related_activity: 0,
				allDay: false,
				start_hour: '--',
				end_hour: '--',
				files: []
			},
			modal: false,
			startTime: [
				{value: '--', text: '--'},
				{value: '00:00:00', text: '12:00 am'},
				{value: '00:30:00', text: '12:30 am'},
				{value: '01:00:00', text: '1:00 am'},
				{value: '01:30:00', text: '1:30 am'},
				{value: '02:00:00', text: '2:00 am'},
				{value: '02:30:00', text: '2:30 am'},
				{value: '03:00:00', text: '3:00 am'},
				{value: '03:30:00', text: '3:30 am'},
				{value: '04:00:00', text: '4:00 am'},
				{value: '04:30:00', text: '4:30 am'},
				{value: '05:00:00', text: '5:00 am'},
				{value: '05:30:00', text: '5:30 am'},
				{value: '06:00:00', text: '6:00 am'},
				{value: '06:30:00', text: '6:30 am'},
				{value: '07:00:00', text: '7:00 am'},
				{value: '07:30:00', text: '7:30 am'},
				{value: '08:00:00', text: '8:00 am'},
				{value: '08:30:00', text: '8:30 am'},
				{value: '09:00:00', text: '9:00 am'},
				{value: '09:30:00', text: '9:30 am'},
				{value: '10:00:00', text: '10:00 am'},
				{value: '10:30:00', text: '10:30 am'},
				{value: '11:00:00', text: '11:00 am'},
				{value: '11:30:00', text: '11:30 am'},
				{value: '12:00:00', text: '12:00 pm'},
				{value: '12:30:00', text: '12:30 pm'},
				{value: '13:00:00', text: '1:00 pm'},
				{value: '13:30:00', text: '1:30 pm'},
				{value: '14:00:00', text: '2:00 pm'},
				{value: '14:30:00', text: '2:30 pm'},
				{value: '15:00:00', text: '3:00 pm'},
				{value: '15:30:00', text: '3:30 pm'},
				{value: '16:00:00', text: '4:00 pm'},
				{value: '16:30:00', text: '4:30 pm'},
				{value: '17:00:00', text: '5:00 pm'},
				{value: '17:30:00', text: '5:30 pm'},
				{value: '18:00:00', text: '6:00 pm'},
				{value: '18:30:00', text: '6:30 pm'},
				{value: '19:00:00', text: '7:00 pm'},
				{value: '19:30:00', text: '7:30 pm'},
				{value: '20:00:00', text: '8:00 pm'},
				{value: '20:30:00', text: '8:30 pm'},
				{value: '21:00:00', text: '9:00 pm'},
				{value: '21:30:00', text: '9:30 pm'},
				{value: '22:00:00', text: '10:00 pm'},
				{value: '22:30:00', text: '10:30 pm'},
				{value: '23:00:00', text: '11:00 pm'},
				{value: '23:30:00', text: '11:30 pm'},
			],
			endTime: [
				{value: '--', text: '--'},
				{value: '00:30:00', text: '12:30 am'},
				{value: '01:00:00', text: '1:00 am'},
				{value: '01:30:00', text: '1:30 am'},
				{value: '02:00:00', text: '2:00 am'},
				{value: '02:30:00', text: '2:30 am'},
				{value: '03:00:00', text: '3:00 am'},
				{value: '03:30:00', text: '3:30 am'},
				{value: '04:00:00', text: '4:00 am'},
				{value: '04:30:00', text: '4:30 am'},
				{value: '05:00:00', text: '5:00 am'},
				{value: '05:30:00', text: '5:30 am'},
				{value: '06:00:00', text: '6:00 am'},
				{value: '06:30:00', text: '6:30 am'},
				{value: '07:00:00', text: '7:00 am'},
				{value: '07:30:00', text: '7:30 am'},
				{value: '08:00:00', text: '8:00 am'},
				{value: '08:30:00', text: '8:30 am'},
				{value: '09:00:00', text: '9:00 am'},
				{value: '09:30:00', text: '9:30 am'},
				{value: '10:00:00', text: '10:00 am'},
				{value: '10:30:00', text: '10:30 am'},
				{value: '11:00:00', text: '11:00 am'},
				{value: '11:30:00', text: '11:30 am'},
				{value: '12:00:00', text: '12:00 pm'},
				{value: '12:30:00', text: '12:30 pm'},
				{value: '13:00:00', text: '1:00 pm'},
				{value: '13:30:00', text: '1:30 pm'},
				{value: '14:00:00', text: '2:00 pm'},
				{value: '14:30:00', text: '2:30 pm'},
				{value: '15:00:00', text: '3:00 pm'},
				{value: '15:30:00', text: '3:30 pm'},
				{value: '16:00:00', text: '4:00 pm'},
				{value: '16:30:00', text: '4:30 pm'},
				{value: '17:00:00', text: '5:00 pm'},
				{value: '17:30:00', text: '5:30 pm'},
				{value: '18:00:00', text: '6:00 pm'},
				{value: '18:30:00', text: '6:30 pm'},
				{value: '19:00:00', text: '7:00 pm'},
				{value: '19:30:00', text: '7:30 pm'},
				{value: '20:00:00', text: '8:00 pm'},
				{value: '20:30:00', text: '8:30 pm'},
				{value: '21:00:00', text: '9:00 pm'},
				{value: '21:30:00', text: '9:30 pm'},
				{value: '22:00:00', text: '10:00 pm'},
				{value: '22:30:00', text: '10:30 pm'},
				{value: '23:00:00', text: '11:00 pm'},
				{value: '23:30:00', text: '11:30 pm'},
			],
			requiredRules: [
				v => !!v || 'This field is required'
			],
		}),
		computed: {
			...mapState({
				properties: state => state.propertyModule.properties,
				property: state => state.propertyModule.property,
				activities: state => state.activityModule.activities,
			})
		},
		watch: {
			'timeLog.allDay'(evt){
				if(evt === true){
					this.timeLog.start_hour = '--'
					this.timeLog.end_hour = '--'
					let noOfDays = this.timeLog.date.end == null ? 1 : Math.ceil(Math.abs(new Date(dayjs(this.timeLog.date.end).format('YYYY/MM/DD ')+'23:59:59') - new Date(dayjs(this.timeLog.date.start).format('YYYY/MM/DD ')+'00:00:00')) / (1000 * 60 * 60 * 24))
					this.timeLog.total_hours = 24 * noOfDays
				}
				else{
					this.timeLog.total_hours = 0
				}
			}
		},
		methods: {
			async getProperties(){
				await this.$store.dispatch('fetchProperties')
			},
			async getProperty(propertyId){
				await this.$store.dispatch('fetchProperty', propertyId)
			},
			async getActivities(){
				await this.$store.dispatch('fetchActivities')
			},
			async getContents(){
				this.isLoading = true
				await this.getProperties()
				await this.getActivities()
				this.isLoading = false
			},
			async addTimeLog(){
				this.isLoading = true
				if(this.timeLog.date.end != null){
					this.timeLog.start_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 00:00:00` : `${this.timeLog.date.start} ${this.timeLog.start_hour}`
					this.timeLog.end_time = this.timeLog.allDay == true ? `${this.timeLog.date.end} 23:59:59` : `${this.timeLog.date.end} ${this.timeLog.end_hour}`
				}
				else{
					this.timeLog.start_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 00:00:00` : `${this.timeLog.date.start} ${this.timeLog.start_hour}`
					this.timeLog.end_time = this.timeLog.allDay == true ? `${this.timeLog.date.start} 23:59:59` : `${this.timeLog.date.start} ${this.timeLog.end_hour}`
				}
				await this.$store.dispatch('addTimeLog', this.timeLog)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Added time log event'
				})
				this.isLoading = false
				this.$router.push('/time-log')
			},
			computeTimeDiff(){
				if(this.timeLog.start_hour != '--' && this.timeLog.end_hour != '--'){
					let startDate = dayjs(`${this.timeLog.date.start} ${this.timeLog.start_hour}`).format('YYYY/MM/DD HH:mm:ss')
					let endDate = this.timeLog.date.end == null ? `${this.timeLog.date.start} ${this.timeLog.end_hour}` : `${this.timeLog.date.end} ${this.timeLog.end_hour}`
					let formattedEndDate = dayjs(endDate).format('YYYY/MM/DD HH:mm:ss')
					let milliseconds = Math.abs(new Date(formattedEndDate) - new Date(startDate))
					this.timeLog.total_hours = milliseconds/36e5
				}
			},
			setAttachments(file){
				this.timeLog.files = file.target.files
			}
		},
		mounted(){
			this.getContents()
		}
	}
</script>