<template>
	<div class="page-container flex flex-col">
		<div class="custom-text-field">
			<input name="property" id="property" type="text" placeholder=" " v-model="property.property" autocomplete="nope" />
			<label for="property">Name</label>
		</div>
		<div class="custom-select">
			<select name="state" id="state" type="text" v-model="property.state_id" @change="getSelectedState(property.state_id)">
				<option :value="state.id" v-for="(state, index) in states" :key="`state-${index}`">{{ state.state }}</option>
			</select>
			<label for="state">State</label>
		</div>
		<div class="custom-select">
			<select name="city" id="city" type="text" v-model="property.city_id">
				<option :value="city.id" v-for="(city, index) in cities" :key="`city-${index}`">{{ city.city }}</option>
			</select>
			<label for="city">City</label>
		</div>
		<div class="custom-text-field">
			<input name="street_address" id="street_address" type="text" placeholder=" " v-model="property.street_address" autocomplete="nope" />
			<label for="street_address">Street Address</label>
		</div>
		<div class="custom-text-field">
			<input name="zip_code" id="zip_code" type="text" placeholder=" " v-model="property.zip_code" autocomplete="nope" />
			<label for="zip_code">Zip Code</label>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="save">Add</button>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			property: {
				property: '',
				street_address: '',
				city_id: '',
				zip_code: ''
			},
			types: [],
			isCityLoading: true,
			isStateLoading: true,
			isLoading: false
		}),
		computed: {
			...mapState({
				states: state => state.locationModule.states,
				cities: state => state.locationModule.cities
			})
		},
		methods: {
			async getStates(){
				this.isStateLoading = true
				await this.$store.dispatch('fetchStates')
				this.isStateLoading = false
			},
			async getCitiesByState(stateId){
				this.isCityLoading = true
				await this.$store.dispatch('fetchCitiesByState', stateId)
				this.isCityLoading = false
			},
			getSelectedState(stateId){
				this.property.city_id = ''
				this.isCityLoading = true
				this.property.state_id = stateId
				this.getCitiesByState(stateId)
			},
			async save(){
				this.isLoading = true
				await this.$store.dispatch('addProperty', this.property)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Added property'
				})
				this.isLoading = false
				this.$router.replace('/settings/properties')
			}
		},
		mounted(){
			this.getStates()
		}
	}
</script> 