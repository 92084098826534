<template>
	<div class="page-container">
		<div class="container max-w-full flex flex-col p-3 border-2 bg-white rounded-md">
			<span class="mb-3 text-gray-600">Remind me to enter time for the last:</span>
			<div class="grid grid-cols-3 gap-2">
				<div 
					class="rounded text-sm text-center py-2" v-for="(option, index) in options" 
					:class="[ weekInputReminder == option.value ? activeClass : inactiveClass]"
					:key="index"
					@click="setActiveOption(option.value)">
					<span>{{ option.text }}</span>
				</div>
			</div>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="save">Save</button>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			isLoading: false,
			weekInputReminder: '',
			options: [
				{
					value: '1',
					text: '1 Week'
				},
				{
					value: '2',
					text: '2 Weeks'
				},
				{
					value: '3',
					text: '3 Weeks'
				},
				{
					value: '4',
					text: '4 Weeks'
				}
			],
			activeClass: 'bg-custom-blue text-white',
			inactiveClass: 'bg-blue-50 text-gray-500'
		}),
		computed: {
			...mapState({
				notificationSettings: state => state.notificationModule.notificationSettings
			})
		},
		watch: {
			notificationSettings(){
				this.weekInputReminder = this.notificationSettings.week_input_reminder
			}
		},
		methods: {
			async getNotificationSettings(){
				this.isLoading = true
				this.$store.dispatch('getNotificationSettings')
				this.isLoading = false
			},
			async save(){
				this.isLoading = true
				await this.$store.dispatch('updateNotifications', {
					week_input_reminder: this.weekInputReminder
				})
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Updated notifications'
				})
				this.$router.replace('/settings')
				this.isLoading = false
			},
			setActiveOption(option){
				this.weekInputReminder = option
			}
		},
		async mounted(){
			await this.getNotificationSettings()
		}
	}
</script>