export default {
	state: {
		properties: [],
		property: []
	},
	mutations: {
		SET_PROPERTIES(state, properties){
			state.properties = properties
		},
		SET_PROPERTY(state, property){
			state.property = property
		}
	},
	getters: {
		countProperties(state){
			return state.properties.length
		}
	},
	actions: {
		async addProperty({commit}, property){
			await axios.post(`${this.state.endpoint}/property`, property)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async editProperty({commit}, property){
			await axios.patch(`${this.state.endpoint}/property/${property.property_id}`, property)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async fetchProperties({commit}){
			const response = await axios.get(`${this.state.endpoint}/properties`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_PROPERTIES', response.data.data)
		},
		async fetchProperty({commit}, propertyId){
			const response = await axios.get(`${this.state.endpoint}/property/${propertyId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_PROPERTY', response.data.data)
		},
		clearProperty({commit}){
			commit('SET_PROPERTY', [])
		},
		async deleteProperty({commit}, propertyId){
			await axios.delete(`${this.state.endpoint}/property/${propertyId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		}
	}
}