<template>
	<div class="relative container max-w-full grid grid-cols-6 bg-transparent h-14 border-gray-200 border-b-2 py-3">
		<!-- header -->
		<div class="col-start-1 focus:outline-none">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-4 text-custom-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="!$route.meta.showBack" @click="drawer = true">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
			</svg>
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-4 text-custom-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else @click="back">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
			</svg>
		</div>
		<div class="col-span-4 text-center">
			<span class="text-custom-blue font-bold text-lg">{{ $route.name }}</span>
		</div>
		<div class="col-end-7 flex justify-center">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-custom-blue" viewBox="0 0 20 20" fill="currentColor">
				<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
			</svg>
		</div>
		<!-- sidebar -->
		<div class="fixed container max-w-full bg-transparent min-h-screen mt-0 inset-y-0 flex transform duration-300 ease-in-out z-20" :class="{'-translate-x-full': !drawer}">
			<div class="container w-60 bg-white min-h-screen shadow-2xl z-10  flex-col">
				<div class="flex flex-col mx-3 border-b-2 h-32 border-gray-200 pt-5">
					<svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-custom-blue" viewBox="0 0 20 20" fill="currentColor">
						<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
					</svg>
					<span class="mt-3 text-sm text-yellow-500 font-bold">{{ fullName }}</span>
					<span class="text-xs text-custom-blue">{{ email }}</span>
				</div>
				<nav class="mt-5">
					<router-link
						tag="div"
						class="flex pl-3 py-3 group text-gray-500 hover:bg-yellow-100 hover:text-yellow-500 font-semibold text-sm"
						:class="{'bg-yellow-100 text-yellow-500': $route.name == menu.name}"
						v-for="(menu, index) in menus" 
						:key="index" 
						:to="menu.path"
						@click.native="drawer = false">
						<span v-html="menu.icon" class="mr-3 group-hover:text-yellow-500"></span>
						<span>{{ menu.name }}</span>
					</router-link>
					<a href="#" class="flex pl-3 py-3 group text-gray-500 hover:bg-yellow-100 hover:text-yellow-500 font-semibold text-sm" @click="logout">
						<div class="mr-3 group-hover:text-yellow-500">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
							</svg>
						</div>
						<span>Log out</span>
					</a>
				</nav>
			</div>
			<div class="bg-transparent flex-grow" @click="drawer = false"></div>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
    export default{
    	data: () => ({
    		drawer: false,
    		fullName: '',
			email: '',
			menus: [
				{
					path: '/dashboard', 
					name: 'Dashboard', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
					</svg>`
				},
				{
					path: '/time-log', 
					name: 'Time Log', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
					</svg>`
				},
				{
					path: '/gps-mile-tracker', 
					name: 'GPS Mile Tracker', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
					</svg>`
				},
				{
					path: '/reports', 
					name: 'Reports', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
					</svg>`
				},
				{
					path: '/receipt-manager', 
					name: 'Receipt Manager', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
					</svg>`
				},
				{
					path: '/calculators', 
					name: 'Calculators', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
					</svg>`
				},
				{
					path: '/tools', 
					name: 'Tools', 
					icon: `<svg class="h-6 w-6"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>
					<path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6-6a6 6 0 0 1 -8 -8l3.5 3.5" />
					</svg>`
				},
				{
					path: '/settings', 
					name: 'Settings', 
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
					</svg>`
				},
				{
					path: '/help',
					name: 'Help',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>`
				},
				{
					path: '/gps-mile-tracker/logs',
					name: 'Logs',
					icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>`
				}
			],
    	}),
    	computed: {
    		...mapState({
    			endpoint: state => state.endpoint
    		})
    	},
    	methods: {
    		logout(){
    			axios.get(`${this.endpoint}/logout`).then(response => {
    				localStorage.removeItem('bearerToken')
    				localStorage.removeItem('userFullName')
    				localStorage.removeItem('email')
    				this.$router.replace('/login')
    			}).catch(error => {
					if(error.response.status == 401){
						this.$store.dispatch('redirectToLogin')
					}
				})
    		},
    		back(){
    			if(this.$route.meta.backTo){
    				this.$router.replace(this.$route.meta.backTo)
    			}
    			else{
    				this.$router.go(-1)
    			}
    		}
    	},
    	mounted(){
    		this.fullName = localStorage.getItem('userFullName')
    		this.email = localStorage.getItem('email')
    	}
    }
</script>