<template>
    <div class="page-container text-sm">
        <Fullcalendar 
            :header="header"
            :titleFormat="titleFormat"
            @eventClick="showEvent" 
            :plugins="plugins" 
            :events="eventList"
            :height="450"
            selectable="true"
            @dateClick="dateClick"
            class="text-yellow-500"
        />
        <router-link class="btn-fab" to="/time-log/add">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
		</router-link>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Fullcalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';

import { mapState, mapGetters } from 'vuex'

export default {
    components: {
        Fullcalendar
    },
    data: () => ({
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        header: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        titleFormat: {
            year: 'numeric',
            month: 'short'
        },
        isLoading: false
    }),
    computed: {
        ...mapGetters([
            'eventList'
        ])
    },
    methods: {
        showEvent(evt) {
            this.$router.push(`/time-log/edit/${evt.event.id}`)
        },
        dateClick(evt){
            console.log(evt)
        },
        async getTimeLogs(){
            await this.$store.dispatch('getTimeLogs')
        }
    },
    async mounted(){
        this.isLoading = true
        await this.getTimeLogs()
        this.isLoading = false
    }
}
</script>

<style lang="css">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

.fc-title {
  color: #fff;
}

.fc-title:hover {
  cursor: pointer;
}

.fc-button-primary{
    background: #223F85 !important;
    border-color: #223F85 !important;
}

.fc-icon{
    margin-bottom: 3px !important;
}
</style>

