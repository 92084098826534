import Vue from 'vue'
import router from '../router'

Vue.mixin({
	data: () => ({
		
	}),
	methods: {
		
	}
})

export default {

}