<template>
	<div class="page-container">
		<div class="custom-text-field">
			<input name="current_password" id="current_password" type="password" placeholder=" " v-model="passwordForm.currentPassword" autocomplete="nope" />
			<label for="current_password">Current Password</label>
		</div>
		<div class="custom-text-field">
			<input name="new_password" id="new_password" type="password" placeholder=" " v-model="passwordForm.newPassword" autocomplete="nope" />
			<label for="new_password">New Password</label>
		</div>
		<div class="custom-text-field">
			<input name="confirm_password" id="confirm_password" type="password" placeholder=" " v-model="passwordForm.confirmNewPassword" autocomplete="nope" />
			<label for="confirm_password">Confirm New Password</label>
		</div>
		<div class="bottom-button-group">
			<button class="btn btn-primary mb-3" @click="changePassword">Change Password</button>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	export default{
		data: () => ({
            isLoading: false,
            passwordForm: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
		}),
		computed: {

		},
		methods: {
			async changePassword(){
				await this.$store.dispatch('changePassword', this.passwordForm)
            }
		},
		mounted(){

		}
	}
</script> 