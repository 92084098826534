<template>
    <div class="flex flex-col container max-w-full border-2 border-gray-200 bg-white rounded-lg my-2 px-3 py-3 text-xs font-medium">
        <div class="inline-flex my-2">
            <img :src="'../storage/img/origin.svg'" class="h4 w-4 mr-1" alt="">
            <div class="flex flex-col text-gray-500">
                <span>
                    {{ report.start_date }} {{ report.start_time }}
                </span>
                <span>{{ report.origin_loc }}</span>
            </div>
        </div>
        <div class="inline-flex my-2 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <div class="flex flex-col">
                <span>
                    {{ report.end_date }} {{ report.end_time }}
                </span>
                <span>{{ report.destination_loc }}</span>
            </div>
        </div>
        <hr>
        <div>
            <div class="flex justify-between mt-2 text-gray-500">
                <div class="inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-custom-blue h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-1 font-semibold">
                        {{ report.total_hours }} 
                        <span v-if="report.total_hours > 1">hours</span>
                        <span v-else>hour</span>
                    </span>
                </div>
                <div class="inline-flex">
                    <img :src="'../storage/img/destination.svg'" class="h4 w-4 mr-1" alt="">
                    {{ report.distance }} km
                </div>
                <div class="flex" v-if="report.type">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 my-auto mr-1 text-custom-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                    </svg>
                    <span class="my-auto text-gray-500">
                        {{ report.type }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        isHidden: false
    }),
    filters: {
        
    },
    props: [
        'report'
    ],
    methods: {
        toggleActivity(){

        }
    }
}
</script>