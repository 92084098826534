<template>
	<div class="flex dialog-background">
		<button class="absolute top-0 right-0 mr-3 mt-3 border-white border-2 rounded" @click="$emit('close')">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
			</svg>
		</button>
		<div class="relative bg-white text-gray-500 m-auto rounded h-auto w-11/12 sm:w-3/4 md:w-10/12 p-2">
			<!-- image preview -->
			<template v-if="!showFolderTree">
				<img :src="image.url" alt="" loading="lazy" class="static mx-auto">
				<div class="w-full h-10 flex justify-evenly">
					<button class="flex-1 focus:outline-none" @click="download">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
						</svg>
					</button>
					<button class="flex-1 focus:outline-none" @click="showFolderTree = true" v-if="movebtn">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
						</svg>
					</button>
					<button class="flex-1 focus:outline-none" @click="showConfirmationDialog = true">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
						</svg>
					</button>
				</div>
			</template>
			<!-- folder tree -->
			<template v-else>
				<FolderTree v-if="showFolderTree" :id="image.id" />
			</template>
		</div>
		<ConfirmationDialog v-if="showConfirmationDialog">
			<template v-slot:message>
				Are you sure you want to delete?
			</template>
			<template v-slot:action>
				<button @click="deleteReceiptManagerAttachment(image.id)" v-if="movebtn">OK</button>
				<button @click="deleteTimeLogAttachment(image.id)" v-else>OK</button>
				<button @click="showConfirmationDialog = false">Cancel</button>
			</template>
		</ConfirmationDialog>
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import FolderTree from '../views/ReceiptManager/FolderTree'

	export default{
		props: {
			folders: {
				Type: Array,
				default: null
			},
			image: {
				Type: Array
			},
			movebtn :{
				Type: Boolean,
				default: true
			}
		},
		components: {
			FolderTree
		},
		data: () => ({
			imagePreviewDialog: true,
			message: '',
			action: '',
			showConfirmationDialog: false,
			showFolderTree: false
		}),
		computed: {
			...mapState({
				endpoint: state => state.endpoint
			})
		},
		methods: {
			closeDialog(){
				dialog: false
				this.$emit('close')
			},
			async deleteReceiptManagerAttachment(attachmentId){
				await this.$store.dispatch('deleteReceiptManagerAttachment', attachmentId)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Deleted attachment'
				})
				this.$router.go()
			},
			async deleteTimeLogAttachment(attachmentId){
				await this.$store.dispatch('deleteTimeLogAttachment', attachmentId)
				this.$store.commit('SET_SNACKBAR_MSG', {
					isActive: true,
					text: 'Deleted attachment'
				})
				this.$router.go()
			},
			showConfirmation(){
				this.showConfirmationDialog = true
			},
			download(){
				let filename = this.image.url.split("/images/")
				axios.get(`${this.endpoint}/receipt-manager/${filename[1]}`, {
					responseType: 'blob'
				}).then((response) => {
					let url = window.URL.createObjectURL(new Blob([response.data]))
					let link = document.createElement('a')

					link.href = url
					link.setAttribute('download', this.image.name)
					document.body.appendChild(link)

					link.click()
				})
			}
		}
	}
</script>