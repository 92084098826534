<template>
	<div class="page-container">
		<div class="max-w-full flex flex-col">
			<div class="max-w-full flex justify-between">
				<span class="font-bold text-gray-600 text-lg">Overall Hours</span>
				<span class="text-yellow-500 font-bold text-lg">{{ reportsMeta.overallHours }}</span>
			</div>
			<div class="max-w-full flex justify-between" v-if="card == 'gpsmiletracker'">
				<span class="font-bold text-gray-600 text-lg">Total Distance (km)</span>
				<span class="text-yellow-500 font-bold text-lg">{{ reportsMeta.totalDistance }}</span>
			</div>
			<span class="text-gray-500" v-if="reportsMeta.dateFrom != undefined">From {{ reportsMeta.dateFrom }} to {{ reportsMeta.dateTo }}</span>
			<span class="text-gray-500" v-else>{{ reportsMeta.limit }} Latest 
				<template v-if="reportFrom == 'timelog'">Events</template>
				<template v-else>Trips</template>
			</span>
		</div>
		<div>
			<template v-if="reports.length != 0">
				<template v-if="card == 'timelog'">
					<TimeLogCard v-for="(report, index) in reports" :key="index" :report="report"></TimeLogCard>
				</template>
				<template v-else>
					<GpsMileTrackerCard v-for="(report, index) in reports" :key="index" :report="report"></GpsMileTrackerCard>
				</template>
			</template>
			<template v-else>
				<div class="text-center w-full mt-5 text-gray-500">
					No data found
				</div>
			</template>
			<!-- added blank div for extra space to prevent floating action button to block some parts of the report -->
			<div class="h-20"></div>
		</div>
		<button class="btn-fab" @click="datepickerDialog = true">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
			</svg>
		</button>
		<button class="btn-fab-secondary" @click="sendEmailDialog = true">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
			</svg>
		</button>
		<!-- send email dialog -->
		<div class="flex dialog-background" v-if="sendEmailDialog || datepickerDialog">
			<!-- modal -->
			<div class="bg-white text-gray-500 m-auto rounded h-auto w-11/12 md:w-60 py-5 px-2 flex flex-col" v-if="sendEmailDialog">
				<div class="custom-text-field flex-1">
					<input name="email" id="email" type="text" placeholder=" " v-model="email" autocomplete="nope" />
					<label for="email">Email</label>
				</div>
				<div class="flex justify-end">
					<button @click="sendEmailDialog = false">
						Cancel
					</button>
					<button @click="sendReportToEmail" class="inline-flex text-white p-2 rounded bg-custom-blue ml-5">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transform rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
						</svg>
						Send
					</button>
				</div>
			</div>
			<div class="bg-white text-gray-500 m-auto rounded h-auto w-3/4 md:w-60 py-5 px-2 flex flex-col" v-else>
				<div class="custom-select">
					<select name="reportFrom" id="reportFrom" type="text" v-model="reportFrom">
						<option value="timelog">Time Logs</option>
						<option value="gps">GPS Mile Tracker</option>
					</select>
					<label for="reportFrom">Report For</label>
				</div>
				<template v-if="reportFrom == 'timelog'">
					<div class="custom-select">
						<select name="property" id="property" type="text" v-model="property">
							<option value="all">All</option>
							<option :value="property.property_id" v-for="(property, index) in properties" :key="`property-${index}`">{{ property.property }}</option>
						</select>
						<label for="property">Property</label>
					</div>
					<div class="custom-select">
						<select name="activity" id="activity" type="text" v-model="activity">
							<option value="all">All</option>
							<option :value="activity.id" v-for="(activity, index) in activities" :key="`activity-${index}`">{{ activity.activity }}</option>
						</select>
						<label for="activity">Activity</label>
					</div>
				</template>
				<template v-else>
					<div class="custom-select">
						<select name="tripType" id="tripType" type="text" v-model="tripType">
							<option value="all">All</option>
							<option value="1">Personal</option>
							<option value="2">Business</option>
						</select>
						<label for="tripType">Trip Type</label>
					</div>
				</template>
				<VueCtkDateTimePicker
					v-model="reportDate"
					format="YYYY-MM-DD"
					formatted="ll"
					range
					only-date
					no-shortcuts
					color="#385496"
					no-label/>
				<div class="flex mt-2 justify-end">
					<button @click="datepickerDialog = false">
						Cancel
					</button>
					<button @click="getReportsByFilter" class="inline-flex text-white p-2 rounded bg-custom-blue ml-5">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
						</svg>
						Generate
					</button>
				</div>
			</div>
		</div>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	import TimeLogCard from './TimeLogCard'
	import GpsMileTrackerCard from './GpsMileTrackerCard'

	export default{
		components: {
			TimeLogCard,
			GpsMileTrackerCard
		},
		data: () => ({
			isLoading: false,
			datepickerDialog: false,
			sendEmailDialog: false,
			reportDate: null,
			property: 'all',
			activity: 'all',
			email: '',
			reportFrom: 'timelog',
			tripType: 'all',
			card: 'timelog'
		}),
		computed: {
			...mapState({
				reports: state => state.reportsModule.reports,
				reportsMeta: state => state.reportsModule.reportsMeta,
				properties: state => state.propertyModule.properties,
				activities: state => state.activityModule.activities
			})
		},
		watch: {

		},
		methods: {
			async getReportsByFilter(){
				this.datepickerDialog = false
				this.isLoading = true
				if(this.reportFrom == 'timelog'){
					await this.$store.dispatch('fetchTimeLogReports', {
						filter: 'custom',
						property: this.property,
						activity: this.activity,
						date: this.reportDate
					})
					this.card = 'timelog'
				}
				else{
					await this.$store.dispatch('fetchGpsMileTrackerReports', {
						tripType: this.tripType,
						date: this.reportDate
					})
					this.card = 'gpsmiletracker'
				}
				this.isLoading = false
			},
			async getReportsByLatest(){
				this.datepickerDialog = false
				this.isLoading = true
				await this.$store.dispatch('fetchTimeLogReports', {
					filter: 'latest',
					limit: 10
				})
				this.isLoading = false
			},
			getSelectedRecurrence(recurrence){
				this.recurrence = recurrence
			},
			async sendReportToEmail(){
				this.sendEmailDialog = false
				this.isLoading = true
				await this.$store.dispatch('sendReportToEmail', {
					email: this.email,
					reportFrom: this.reportFrom
				})
				this.isLoading = false
			},
			async getProperties(){
				await this.$store.dispatch('fetchProperties')
			},
			async getActivities(){
				await this.$store.dispatch('fetchActivities')
			},
		},
		async mounted(){
			await this.getReportsByLatest()
			this.email = localStorage.getItem('email')
			await this.getProperties()
			await this.getActivities()
		}
	}
</script>