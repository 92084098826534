export default {
	state: {
		activities: [],
		activity: []
	},
	mutations: {
		SET_ACTIVITIES(state, activities){
			state.activities = activities
		},
		SET_ACTIVITY(state, activity){
			state.activity = activity
		}
	},
	getters: {
		countActivities(state){
			return state.activities.length
		}
	},
	actions: {
		async addActivity({commit}, activity){
			await axios.post(`${this.state.endpoint}/activity`, {activity: activity})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async editActivity({commit}, activity){
			await axios.patch(`${this.state.endpoint}/activity/${activity.id}`, activity)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async fetchActivities({commit}){
			const response = await axios.get(`${this.state.endpoint}/activities`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_ACTIVITIES', response.data.data)
		},
		async fetchActivity({commit}, activityId){
			const response = await axios.get(`${this.state.endpoint}/activity/${activityId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
			commit('SET_ACTIVITY', response.data.data)
		},
		async deleteActivity({commit}, activityId){
			await axios.delete(`${this.state.endpoint}/activity/${activityId}`)
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		}
	}
}