<template>
	<div class="page-container">
		<router-link tag="div" class="flex flex-wrap content-center rounded-lg bg-white px-4 mb-3 border-2" v-for="(activity, index) in activities" :key="index" :to="`/settings/activities/${activity.id}`">
			<span class="text-yellow-500 font-semibold my-2">{{ activity.activity }}</span>
		</router-link>
		<router-link class="btn-fab" to="/settings/activities/add">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
			</svg>
		</router-link>
		<Loading v-if="isLoading" />
	</div>
</template>
<script>
	import { mapState } from 'vuex'
	export default{
		data: () => ({
			activityForm: [],
			isLoading: false
		}),
		computed: {
			...mapState({
				activities: state => state.activityModule.activities
			})
		},
		methods: {
			async getActivities(){
				await this.$store.dispatch('fetchActivities')
			}
		},
		async mounted(){
			this.isLoading = true
			await this.getActivities()
			this.isLoading = false
		}
	}
</script>