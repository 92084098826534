<template>
    <div class="flex flex-col container max-w-full border-2 border-gray-200 bg-white rounded-lg my-2 px-3 py-3 text-xs font-medium">
        <div class="flex justify-between my-2">
            <span class="text-yellow-500">Property</span>
            <span class="text-gray-500">{{ report.property }}</span>
        </div>
        <div class="flex justify-between my-2">
            <span class="text-gray-500 flex">
                <img :src="'../storage/img/origin.svg'" class="h4 w-4 mr-1" alt="">
                {{ report.start_time }}
            </span>
            <span class="text-gray-500">{{ report.start_date }}</span>
        </div>
        <div class="flex justify-between my-2">
            <span class="text-gray-500 flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                {{ report.end_time }}
            </span>
            <span class="text-gray-500">{{ report.end_date }}</span>
        </div>
        <div class="flex justify-between my-2">
            <span class="text-gray-500">Is Real Estate Related Activity</span>
            <span class="text-gray-500">{{ report.is_real_estate_related_activity }}</span>
        </div>
        <hr>
        <div>
            <div class="flex justify-between mt-2 text-gray-500">
                <div class="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-custom-blue h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-1 font-semibold">
                        {{ report.total_hours }} 
                        <span v-if="report.total_hours > 1">hours</span>
                        <span v-else>hour</span>
                    </span>
                </div>
                <div>
                    <span class="font-semibold text-custom-blue" @click="isHidden = !isHidden">View Activity &amp; Note</span>
                </div>
            </div>
            <div class="flex flex-col mt-3" :class="{ 'hidden': !isHidden }">
                <span class="text-yellow-500">Activity</span>
                <p class="text-gray-500 mt-2">{{ report.activity }}</p>
                <span class="text-yellow-500 mt-2">Note</span>
                <p class="text-gray-500 mt-2" v-if="report.note != null">{{ report.note }}</p>
                <p class="text-gray-500 mt-2" v-else>--</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        isHidden: false
    }),
    props: [
        'report'
    ],
    methods: {
        toggleActivity(){

        }
    }
}
</script>