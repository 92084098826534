export default {
	state: {
		notificationSettings: []
	},
	mutations: {
		SET_NOTIFICATION_SETTINGS(state, notificationSettings){
			state.notificationSettings = notificationSettings
		}
	},
	getters: {

	},
	actions: {
		async getNotificationSettings({commit}){
			await axios.get(`${this.state.endpoint}/notification`)
			.then(response => {
				commit('SET_NOTIFICATION_SETTINGS', response.data.data)
			})
			.catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		},
		async updateNotifications({commit}, notificationSettings){
			await axios.post(`${this.state.endpoint}/notification/week-reminder`, {
				week_input_reminder: notificationSettings.week_input_reminder
			}).then(response => {

			}).catch(error => {
				if(error.response.status == 401){
					this.dispatch('redirectToLogin')
				}
			})
		}
	}
}