<template>
	<div class="container bg-dark-blue h-screen max-w-full">
		<div class="container absolute inset-x-0 bottom-0 h-3/4 bg-white pt-14 rounded-tl-3xl max-w-full">
			<div class="mx-5 flex flex-col">
				<div class="flex mb-10">
					<span class="m-auto font-bold text-2xl text-custom-blue">Login</span>
				</div>
				<div class="my-4 border-b-2 focus-within:border-blue-500">
					<label for="email" class="text-yellow-500 font-bold">Email</label>
					<input name="email" class="w-full block appearance-none focus:outline-none bg-transparent text-gray-800" type="text" v-model="loginForm.email" placeholder="Email" />
				</div>
				<div class="my-4 border-b-2 focus-within:border-blue-500">
					<label for="password" class="text-yellow-500 font-bold">Password</label>
					<input name="password" class="w-full block appearance-none focus:outline-none bg-transparent text-gray-800" type="password" v-model="loginForm.password" placeholder="Password" />
				</div>
				<div class="grid justify-items-end">
					<router-link class="text-yellow-500 pb-5" to="forgot-password">Forgot Password?</router-link>
					<button class="btn btn-primary mt-5" @click="login">Log in</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data: () => ({
        	loginForm: {
				email: '',
				password: ''
			},
			isLoading: false
        }),
		methods: {
			login(){
				this.isLoading = true;
				axios.post('/api/v1/login', this.loginForm).then(response => {
					this.isLoading = false;
					if(response.data.access_token){
						localStorage.setItem('bearerToken', response.data.access_token);
						localStorage.setItem('email', response.data.user.email);
						localStorage.setItem('userFullName', `${response.data.user.first_name} ${response.data.user.last_name}`);
						axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
						this.$router.push('/dashboard');
						this.$parent.initLocation()
					}
					else{
						this.$store.commit('SET_SNACKBAR_MSG', {
							isActive: true,
							text: response.data.message
						})
					}
				}).catch(error => {

				});
			}
		}
	}
</script>