<template>
	<v-sheet>
		<v-container>
			<v-row>
				<v-col cols="12" offset="2">
					<h4>Your device is offline</h4>
				</v-col>
			</v-row>
		</v-container>
	</v-sheet>
</template>
<script>
	export default{

	}
</script>